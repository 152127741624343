import {Badge, Button, Dropdown, Spinner, Table} from "react-bootstrap";
import {
    ArrowRepeat,
    LightningCharge,
    LightningChargeFill,
    StopFill
} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {PaginationDirectionControls} from "../Utils/Pagination";
import {useEffect, useReducer, MouseEvent} from "react";
import {callDispatch, CallState, CallUpdate, newCallWithMetadata} from "../Utils/Calls";
import {TaskInfo, TaskListInfo, TaskSourceType} from "../Core/Model";
import {useApi} from "../Core/ApiContextProvider";

import './Styles/Tasks.css'
import {TaskDetailsModal} from "./TaskDetailsModal";
import {useCache} from "../Utils/UserCache";
import {DotsToggle} from "../Utils/DotsToggle";
import {TaskInterruptModal} from "./TaskInterrupt";

interface TaskListMetadata {
    showAutoTasks: boolean
}

interface TaskListState {
    tasks: CallState<TaskListInfo, TaskListMetadata>,
    selectedTaskId: number | null,
    interruptTask: TaskInfo | null
}

type TaskListEvent =
    {
        type: 'CALL_UPDATE',
        call: 'tasks',
        data: CallUpdate
    } |
    {
        type: 'SELECT_TASK',
        taskId: number
    } |
    {
        type: 'CLOSE_TASK'
    } |
    {
        type: 'INTERRUPT_TASK',
        task: TaskInfo
    } |
    {
        type: 'CLOSE_INTERRUPT'
    }

function newState(limit: number, showAutoTasks: boolean): TaskListState {
    return {
        tasks: newCallWithMetadata({ showAutoTasks: showAutoTasks }, limit),
        selectedTaskId: null,
        interruptTask: null
    }
}

export const TaskList = () => {
    const cache = useCache()
    const api = useApi()
    const [state, dispatchState] = useReducer((state: TaskListState, event: TaskListEvent) => {
        if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case "tasks":
                    return { ...state, tasks: callDispatch(state.tasks, event.data) }
            }
        } else if (event.type === 'SELECT_TASK') {
            return { ...state, selectedTaskId: event.taskId }
        } else if (event.type === 'CLOSE_TASK') {
            return { ...state, selectedTaskId: null }
        } else if (event.type === 'INTERRUPT_TASK') {
            return { ...state, interruptTask: event.task }
        } else if (event.type === 'CLOSE_INTERRUPT') {
            return { ...state, interruptTask: null, tasks: { ...state.tasks, refreshIndicator: !state.tasks.refreshIndicator } }
        }
        return state
    }, newState(cache.number('tasks_limit', 10), cache.boolean('tasks_show_auto', true)))
    const onTaskInterruptToggle = (task: TaskInfo) => {
        dispatchState({ type: 'INTERRUPT_TASK', task: task })
    }
    const onTaskClick = (event: MouseEvent<HTMLElement>, task: TaskInfo) => {
        dispatchState({ type: 'SELECT_TASK', taskId: task.task_id })
    }
    useEffect(() => {
        const controller = new AbortController()
        dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'LOADING', message: 'Tasks are loading...' } })
        const sources = state.tasks.metadata.showAutoTasks ? [] : [ TaskSourceType.USER ]
        api.tasksList(state.tasks.pagination.offset, state.tasks.pagination.limit, sources, controller)
            .then(botList => dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'DATA', data: botList } }))
            .catch(error => dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'ERROR', error: error } }))
        return () => controller.abort()
    }, [state.tasks.refreshIndicator, state.tasks.pagination.limit, state.tasks.pagination.offset, state.tasks.metadata.showAutoTasks])
    return (
        <>
            {state.interruptTask !== null ? <TaskInterruptModal task={state.interruptTask} onClose={() => dispatchState({ type: 'CLOSE_INTERRUPT' })}/> : null}
            {state.selectedTaskId !== null ? <TaskDetailsModal taskId={state.selectedTaskId} onClose={() => dispatchState({ type: 'CLOSE_TASK' })} /> : null}
            <div className='table-wrapper'>
                <div className='table-toolbar'>
                    <Button disabled={state.tasks.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'REFRESH' } })}>
                        <div className='icon-button'>
                            <ArrowRepeat size='1.2em' title='Refresh' />
                        </div>
                    </Button>
                    <SimpleTooltip tooltip={state.tasks.metadata.showAutoTasks ? 'Hide automatic tasks' : 'Show automatic tasks'}>
                        <Button disabled={state.tasks.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'SET_METADATA', metadata: { ...state.tasks.metadata, showAutoTasks: cache.setBoolean('tasks_show_auto', !state.tasks.metadata.showAutoTasks) } } })}>
                            <div className='icon-button'>
                                {state.tasks.metadata.showAutoTasks ? <LightningChargeFill size='1.2em' title='Show/Hide automatic tasks' /> :
                                    <LightningCharge size='1.2em' title='Show/Hide automatic tasks' />}
                            </div>
                        </Button>
                    </SimpleTooltip>
                </div>
                <div className='overflow-table-wrapper'>
                    <Table responsive='xl' striped hover className='full-width'>
                        <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Created
                            </th>
                            <th>
                                Type
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Last Update
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.tasks.data !== null && state.tasks.data.tasks.length > 0 ?
                            state.tasks.data.tasks.map(task => {
                                return (
                                    <tr className='cursor-pointer' key={task.task_id}>
                                        <td onClick={event => onTaskClick(event, task)}>
                                            {task.task_id}
                                        </td>
                                        <td onClick={event => onTaskClick(event, task)}>
                                            <SimpleTooltip tooltip={<div>UTC: {task.create_date.utc}</div>}>
                                                {task.create_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td onClick={event => onTaskClick(event, task)}>
                                            <SimpleTooltip tooltip={<div>Type ID: {task.task_type}</div>}>
                                                <Badge bg={task.task_type_bg}>{task.task_type_name}</Badge>
                                            </SimpleTooltip>
                                        </td>
                                        <td onClick={event => onTaskClick(event, task)}>
                                            <SimpleTooltip tooltip={<div>Status ID: {task.status}</div>}>
                                                <Badge bg={task.status_bg}>{task.status_name}</Badge>
                                            </SimpleTooltip>
                                        </td>
                                        <td onClick={event => onTaskClick(event, task)}>
                                            <SimpleTooltip tooltip={<div>UTC: {task.update_date.utc}</div>}>
                                                {task.update_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td onClick={event => onTaskClick(event, task)}>
                                            <div className='task-list-params'>
                                                <span style={{opacity: task.params_count === 0 ? 0.5 : 1}}>{task.params_count} param(s)</span>
                                                {task.is_auto_task ? <SimpleTooltip tooltip='This task is scheduled automatically'>
                                                    <LightningChargeFill />
                                                </SimpleTooltip> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <Dropdown autoClose={true}>
                                                <Dropdown.Toggle as={DotsToggle}></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.ItemText>
                                                        Task {task.task_id}
                                                    </Dropdown.ItemText>
                                                    <Dropdown.Divider />

                                                    {task.can_interrupt ? <Dropdown.Item onClick={() => onTaskInterruptToggle(task)}>
                                                        <div className='icon-button'>
                                                            <StopFill color='red' />
                                                            <span className='text-danger'>Interrupt execution</span>
                                                        </div>
                                                    </Dropdown.Item> : <Dropdown.Item disabled={true}>No actions available</Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        {state.tasks.data !== null && state.tasks.data.tasks.length === 0 ?
                            <tr>
                                <td colSpan={5} className='center-text'>
                                    Empty list of tasks
                                </td>
                            </tr> : null}
                        {state.tasks.error !== null && !state.tasks.loading ?
                            <tr>
                                <td colSpan={6} className='center-text'>
                                    <div>Error occurred loading tasks</div>
                                    <div>{state.tasks.error.message}</div>
                                </td>
                            </tr>
                        : null}
                        </tbody>
                    </Table>
                </div>
                <PaginationDirectionControls
                    call={state.tasks}
                    show={!state.tasks.loading}
                    totalCountFn={(data) => data.total_count}
                    onPaginationControl={(d) => dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'PAGINATION', direction: d } })}
                    onPaginationLimit={(limit) => dispatchState({ type: 'CALL_UPDATE', call: 'tasks', data: { type: 'LIMIT', limit: cache.setNumber('tasks_limit', limit) } })} />
                {state.tasks.loading ? <div className='loading-wrapper'>
                    <Spinner />
                    {state.tasks.message !== null && <span className='loading-message'>
                        {state.tasks.message}
                    </span>}
                </div> : null}
            </div>
        </>
    )
}