import {Button, Modal, Spinner, Table} from "react-bootstrap";
import {ProxyInput} from "./ProxyInput";
import {AddProxiesRequest, AddProxiesResponse, ProxyData} from "../Core/Model";
import {useReducer} from "react";
import {Check2Circle, ExclamationCircle, Trash} from "react-bootstrap-icons";

import './Styles/Proxies.css'
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useApi} from "../Core/ApiContextProvider";

export interface CreateProxyModalProps {
    show: boolean,
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
}

interface CreateProxyState {
    proxies: ProxyData[]
    create: CallState<AddProxiesResponse>
}

type CreateProxyEvent = {
    type: 'CALL_UPDATE',
    call: 'create',
    update: CallUpdate
} | {
    type: 'ADD_PROXY',
    proxy: ProxyData
} | {
    type: 'REMOVE_PROXY',
    index: number
} | {
    type: 'RESET'
}

function newState(): CreateProxyState {
    return {
        proxies: [],
        create: newCall()
    }
}

export const CreateProxyModal = (props: CreateProxyModalProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: CreateProxyState, event: CreateProxyEvent) => {
        if (event.type === 'ADD_PROXY') {
            return { ...state, proxies: [...state.proxies, event.proxy] }
        } else if (event.type === 'REMOVE_PROXY') {
            return { ...state, proxies: state.proxies.filter((_, index) => index !== event.index) }
        } else if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case 'create': {
                    return { ...state, create: callDispatch(state.create, event.update) }
                }
            }
        } else if (event.type === "RESET") {
            return newState()
        }
        return state
    }, newState())
    const onClose = () => {
        dispatchState({ type: 'RESET' })
        props.onClose(state.create.data !== null)
    }
    const onAdd = (data: ProxyData) => {
        dispatchState({ type: 'ADD_PROXY', proxy: data })
        return true;
    }
    const onRemove = (index: number) => {
        dispatchState({ type: 'REMOVE_PROXY', index: index })
    }
    const onSubmit = () => {
        const proxies = state.proxies
        if (proxies.length === 0) {
            return
        }
        const stringProxies = proxies.map(e => {
            if (e.username === undefined) {
                return `${e.host}:${e.port}`
            }
            if (e.password === undefined) {
                return `${e.username}@${e.host}:${e.port}`
            }
            return `${e.username}:${e.password}@${e.host}:${e.port}`
        })
        const request: AddProxiesRequest = { proxies: stringProxies }
        dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'LOADING', message: 'Adding proxies...' } })
        api.proxiesAdd(request)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'DATA', data: e } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'ERROR', error: e } }))
    }
    const getContent = () => {
        if (state.create.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner/>
                    {state.create.message !== null && <span className='loading-message'>
                        {state.create.message}
                   </span>}
                </div>
            )
        }
        if (state.create.data) {
            return (
                <div className='success-message'>
                    <Check2Circle color='green' />
                    <div>{state.create.data.added_proxies.length} proxies created successfully</div>
                </div>
            )
        }
        return (
            <>
                <Table size='sm'>
                    <thead>
                    <tr>
                        <th>Host</th>
                        <th>Port</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.proxies.map((proxy, index) => {
                        return (
                            <tr key={`proxy-${index}`}>
                                <td>{proxy.host}</td>
                                <td>{proxy.port}</td>
                                <td>{proxy.username ?? 'N/A'}</td>
                                <td>{proxy.password ?? 'N/A'}</td>
                                <td className='proxy-create-table-cell-end'>
                                    <Button size='sm' variant='outline-danger' onClick={() => onRemove(index)}>
                                        <Trash />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <ProxyInput enableAdd={true} onAdd={onAdd} />
                {state.create.error &&
                    <div className='error-message mt-2'>
                        <ExclamationCircle size='1.2em' color='red' />
                        <div>
                            <div>Failed to create proxies: {state.create.error.message}</div>
                        </div>
                    </div>}
            </>
        )
    }
    return (
        <Modal size='lg' show={props.show} fullscreen='lg-down'>
            <Modal.Header>
                <Modal.Title>
                    Create proxy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent()}
            </Modal.Body>
            <Modal.Footer>
                {!state.create.data && <Button disabled={state.proxies.length === 0 || state.create.loading} onClick={onSubmit}>Create</Button>}
                <Button disabled={state.create.loading} onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}