import React, {ReactNode, useContext} from "react";
import {Api, ApiError} from "./Api";

export const ApiContext = React.createContext<Api | null>(null);

export const useApi: () => Api = () => {
    const api = useContext(ApiContext)
    if (api === null) {
        throw new ApiError(null, 'Api is not found', null)
    }
    return api
}

export interface ApiProviderProps {
    baseUrl: string,
    children?: ReactNode | undefined
}

export const ApiProvider = ({ baseUrl, children }: ApiProviderProps) => {
    const [api] = React.useState(new Api(baseUrl));
    return (
        <ApiContext.Provider value={api} >
            {children}
        </ApiContext.Provider>
    )
}