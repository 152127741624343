import {ChangeEvent, useEffect, useState} from "react";
import {FieldState} from "../../Utils/FieldState";
import {FormControl, InputGroup} from "react-bootstrap";
import {SimpleTooltip} from "../../Utils/SimpleTooltip";
import {CheckLg, XLg} from "react-bootstrap-icons";
import {useCreateTask} from "./CreateTaskContext";

export const CreateUploadTask = () => {
    const createTask = useCreateTask()
    const [urlValid, setUrlValid] = useState<FieldState<string>>({ valid: false, message: 'You need to enter url of the video' })
    const onUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim()
        if (value.length === 0) {
            setUrlValid({ valid: false, message: 'You need to enter url of the video' })
            return
        }
        setUrlValid({ valid: true, message: 'Ok', data: value })
    }

    useEffect(() => {
        if (urlValid.valid) {
            createTask.dispatchData({ url: urlValid.data })
        } else {
            createTask.dispatchInvalid()
        }
    }, [urlValid])

    return (
        <div>
            <InputGroup>
                <InputGroup.Text>Url</InputGroup.Text>
                <FormControl onChange={onUrlChange} type='text' placeholder='ex. https://www.instagram.com/reel/CqDJNoXDvN7/?igshid=YmMyMTA2M2Y='/>
                <InputGroup.Text>
                    <SimpleTooltip tooltip={urlValid.message}>
                        {urlValid.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
        </div>
    )
}