import {Badge, Button, Dropdown, Spinner, Table} from "react-bootstrap";
import {
    ArrowRepeat,
    CardChecklist,
    PlusLg,
    Trash
} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {PaginationDirectionControls} from "../Utils/Pagination";
import {useEffect, useReducer} from "react";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {BotGroup, BotGroupList} from "../Core/Model";
import {useApi} from "../Core/ApiContextProvider";

import {useCache} from "../Utils/UserCache";
import {DotsToggle} from "../Utils/DotsToggle";
import {CreateGroupModal} from "./CreateGroup";
import {DeleteGroupModal} from "./DeleteGroup";
import {GroupDetailsModal} from "./GroupDetailsModal";

interface GroupListState {
    groups: CallState<BotGroupList>,
    showCreateGroup: boolean,
    deleteGroup: BotGroup | null,
    detailsGroup: BotGroup | null
}

type GroupListEvent =
    {
        type: 'CALL_UPDATE',
        call: 'groups',
        data: CallUpdate
    } |
    {
        type: 'TOGGLE_CREATE_GROUP',
        show: boolean,
        refresh?: boolean
    } |
    {
        type: 'CLOSE_DELETE_GROUP'
    } |
    {
        type: 'DELETE_GROUP',
        group: BotGroup
    } |
    {
        type: 'OPEN_GROUP_DETAILS',
        group: BotGroup
    } |
    {
        type: 'CLOSE_GROUP_DETAILS'
    }

function newState(limit: number): GroupListState {
    return {
        groups: newCall(limit),
        showCreateGroup: false,
        deleteGroup: null,
        detailsGroup: null
    }
}



export const GroupList = () => {
    const cache = useCache()
    const api = useApi()
    const [state, dispatchState] = useReducer((state: GroupListState, event: GroupListEvent) => {
        if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case "groups":
                    return { ...state, groups: callDispatch(state.groups, event.data) }
            }
        } else if (event.type === 'TOGGLE_CREATE_GROUP') {
            return { ...state, showCreateGroup: event.show, groups: { ...state.groups, refreshIndicator: event.refresh !== undefined ? !state.groups.refreshIndicator : state.groups.refreshIndicator } }
        } else if (event.type === 'CLOSE_DELETE_GROUP') {
            return { ...state, deleteGroup: null, groups: { ...state.groups, refreshIndicator: !state.groups.refreshIndicator} }
        } else if (event.type === 'DELETE_GROUP') {
            return { ...state, deleteGroup: event.group }
        } else if (event.type === 'OPEN_GROUP_DETAILS') {
            return { ...state, detailsGroup: event.group }
        } else if (event.type === 'CLOSE_GROUP_DETAILS') {
            return { ...state, detailsGroup: null, groups: { ...state.groups, refreshIndicator: !state.groups.refreshIndicator } }
        }
        return state
    }, newState(cache.number('groups_limit', 10)))
    const onGroupDetails = (group: BotGroup) => {
        dispatchState({ type: 'OPEN_GROUP_DETAILS', group: group })
    }
    useEffect(() => {
        const controller = new AbortController()
        dispatchState({ type: 'CALL_UPDATE', call: 'groups', data: { type: 'LOADING', message: 'Groups are loading...' } })
        api.groupsList(state.groups.pagination.offset, state.groups.pagination.limit, controller)
            .then(groupList => dispatchState({ type: 'CALL_UPDATE', call: 'groups', data: { type: 'DATA', data: groupList } }))
            .catch(error => dispatchState({ type: 'CALL_UPDATE', call: 'groups', data: { type: 'ERROR', error: error } }))
        return () => controller.abort()
    }, [state.groups.refreshIndicator, state.groups.pagination.limit, state.groups.pagination.offset])
    return (
        <>
            <CreateGroupModal show={state.showCreateGroup} onClose={() => dispatchState({ type: 'TOGGLE_CREATE_GROUP', show: false, refresh: true })} />
            {state.deleteGroup && <DeleteGroupModal group={state.deleteGroup} onClose={() => dispatchState({ type: 'CLOSE_DELETE_GROUP' })} />}
            {state.detailsGroup && <GroupDetailsModal groupId={state.detailsGroup.id} onClose={() => dispatchState({ type: 'CLOSE_GROUP_DETAILS' })}/>}
            <div className='table-wrapper'>
                <div className='table-toolbar'>
                    <Button disabled={state.groups.loading} onClick={() => dispatchState({ type: 'TOGGLE_CREATE_GROUP', show: true })}>
                        <div className='icon-button'>
                            <PlusLg  size='1.2em' title='Add' />
                        </div>
                    </Button>
                    <Button disabled={state.groups.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'groups', data: { type: 'REFRESH' } })}>
                        <div className='icon-button'>
                            <ArrowRepeat size='1.2em' title='Refresh' />
                        </div>
                    </Button>
                </div>
                <div className='overflow-table-wrapper'>
                    <Table striped hover className='full-width'>
                        <thead>
                        <tr>
                            <th className='col-1'>
                                ID
                            </th>
                            <th className="col-3">
                                Name
                            </th>
                            <th className="col-6">
                                Description
                            </th>
                            <th className="col-1">
                                Status
                            </th>
                            <th className="col-1"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.groups.data !== null && state.groups.data.groups.length > 0 ?
                            state.groups.data.groups.map(group => {
                                return (
                                    <tr className='cursor-pointer' key={group.id}>
                                        <td className='col-1' onClick={() => onGroupDetails(group)}>
                                            {group.id}
                                        </td>
                                        <td className='col-3' onClick={() => onGroupDetails(group)}>
                                            {group.name}
                                        </td>
                                        <td className='col-6' onClick={() => onGroupDetails(group)}>
                                            <div className='text-cut'>{group.description}</div>
                                        </td>
                                        <td className="col-1" onClick={() => onGroupDetails(group)}>
                                            <SimpleTooltip tooltip={<div>Status ID: {group.status}</div>}>
                                                <Badge bg={group.variant}>{group.status_name}</Badge>
                                            </SimpleTooltip>
                                        </td>
                                        <td className="col-1">
                                            <Dropdown autoClose={true}>
                                                <Dropdown.Toggle as={DotsToggle}></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Header>
                                                        {group.name}
                                                    </Dropdown.Header>
                                                    <Dropdown.Header>
                                                        {group.bot_ids.length} bot(s)
                                                    </Dropdown.Header>
                                                    <Dropdown.Item onClick={() => onGroupDetails(group)}>
                                                        <div className='icon-button text-primary'>
                                                            <CardChecklist /> <span>Add/Remove bots</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Header>Created</Dropdown.Header>
                                                    <Dropdown.Item>
                                                        <SimpleTooltip tooltip={(
                                                            <div>UTC: {group.create_date.utc}</div>
                                                        )}>{group.create_date.local}</SimpleTooltip>
                                                    </Dropdown.Item>
                                                    <Dropdown.Header>Updated</Dropdown.Header>
                                                    <Dropdown.Item>
                                                        <SimpleTooltip tooltip={(
                                                            <div>UTC: {group.update_date.utc}</div>
                                                        )}>{group.update_date.local}</SimpleTooltip>
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={() => dispatchState({ type: 'DELETE_GROUP', group: group })}>
                                                        <div className='icon-button text-danger'>
                                                            <Trash /> <span>Delete</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        {state.groups.data !== null && state.groups.data.groups.length === 0 ?
                            <tr>
                                <td colSpan={5} className='center-text'>
                                    Empty list of groups
                                </td>
                            </tr> : null}
                        {state.groups.error !== null && !state.groups.loading ?
                            <tr>
                                <td colSpan={6} className='center-text'>
                                    <div>Error occurred loading bot groups</div>
                                    <div>{state.groups.error.message}</div>
                                </td>
                            </tr>
                            : null}
                        </tbody>
                    </Table>
                </div>
                <PaginationDirectionControls
                    call={state.groups}
                    show={!state.groups.loading}
                    totalCountFn={(data) => data.total_count}
                    onPaginationControl={(d) => dispatchState({ type: 'CALL_UPDATE', call: 'groups', data: { type: 'PAGINATION', direction: d } })}
                    onPaginationLimit={(limit) => dispatchState({ type: 'CALL_UPDATE', call: 'groups', data: { type: 'LIMIT', limit: cache.setNumber('groups_limit', limit) } })} />
                {state.groups.loading ? <div className='loading-wrapper'>
                    <Spinner />
                    {state.groups.message !== null && <span className='loading-message'>
                        {state.groups.message}
                    </span>}
                </div> : null}
            </div>
        </>
    )
}