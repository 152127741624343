import {CreateBotCredentials} from "../Core/Model";
import {useEffect} from "react";
import {Button, Spinner} from "react-bootstrap";
import {CheckLg, ExclamationCircle, Trash} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {undefinedIfEmpty} from "../Utils/Utils";

export interface BotFileProps {
    file: File,
    state: BotFileState,
    // eslint-disable-next-line no-unused-vars
    dispatchState: (event: BotFileEvent) => void,
    onRemove: () => void
}

export type BotFileLoadingState =
    {
        type: 'loading'
    } |
    {
        type: 'success',
        bots: CreateBotCredentials[]
    } |
    {
        type: 'error',
        message: string
    }

export interface BotFileState {
    fileState: BotFileLoadingState
}

export type BotFileEvent =
    {
        type: 'ERROR',
        message: string
    } |
    {
        type: 'LOADED',
        bots: CreateBotCredentials[]
    }

export function newState(): BotFileState {
    return {
        fileState: { type: 'loading' }
    }
}

export function dispatchState(state: BotFileState, event: BotFileEvent): BotFileState {
    switch (event.type) {
        case "ERROR":
            return {...state, fileState: {type: "error", message: event.message}}
        case "LOADED":
            return {...state, fileState: {type: 'success', bots: event.bots}}
    }
}

export const BotFile = (props: BotFileProps) => {
    const {dispatchState, state} = props
    useEffect(() => {
        if (state.fileState.type === 'loading') {
            const fileReader = new FileReader()
            fileReader.onerror = (event) => {
                if (event.target !== null) {
                    dispatchState({ type: 'ERROR', message: event.target.error?.message ?? 'Unable to load file' })
                }
            }
            fileReader.onprogress = e => console.error('progress', props.file.name, e)
            fileReader.onload = (event) => {
                if (event.target !== null) {
                    const content = event.target.result;
                    if (content === null || typeof content !== 'string') {
                        dispatchState({ type: 'ERROR', message: 'No content loaded from file' })
                        return
                    }
                    const lines = content.split('\n')
                    let bots: CreateBotCredentials[] = []
                    for (let i = 0; i < lines.length; i++) {
                        try {
                            const line = lines[i].trim();
                            if (line.length === 0) {
                                continue
                            }
                            const separationIndex = line.indexOf(':')
                            const lastSeparationIndex = line.lastIndexOf(':')
                            if (separationIndex === -1) {
                                dispatchState({ type: 'ERROR', message: `File parse failure on line ${i + 1}: No ':' found` })
                                return
                            }
                            const login = line.substring(0, separationIndex).trim()
                            const password = lastSeparationIndex > separationIndex
                                ? line.substring(separationIndex + 1, lastSeparationIndex).trim()
                                : line.substring(separationIndex + 1, line.length).trim()
                            const sessionId = lastSeparationIndex > separationIndex
                                ? line.substring(lastSeparationIndex + 1, line.length).trim()
                                : ''
                            bots.push({ login: login, password: password, session_key: undefinedIfEmpty(sessionId) })
                        } catch (e) {
                            dispatchState({ type: 'ERROR', message: `File parse failure on line ${i + 1}: ${e}` })
                            return
                        }
                    }
                    dispatchState({ type: 'LOADED', bots: bots })
                }
            }
            fileReader.onabort = e => console.error('abort', props.file.name, e)
            fileReader.readAsText(props.file)
            return () => fileReader.abort()
        }
    }, [props.file])
    return (
        <tr>
            <td className='bot-create-table-cell'>
                {props.file.name} {props.file.size} bytes
            </td>
            <td className='bot-create-table-cell'>
                {state.fileState.type === 'loading' ? <Spinner /> : null}
                {state.fileState.type === 'error' ?
                    <SimpleTooltip tooltip={state.fileState.message}>
                        <ExclamationCircle color={'red'} />
                    </SimpleTooltip> : null}
                {state.fileState.type === 'success' ? <CheckLg color='green' /> : null}
            </td>
            <td className='bot-create-table-cell'>
                {state.fileState.type === 'success' ? state.fileState.bots.length : ''}
            </td>
            <td className='bot-create-table-cell'>
                <Button size='sm' variant='outline-danger' onClick={() => props.onRemove()}><Trash /></Button>
            </td>
        </tr>
    )
}