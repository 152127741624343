import {BotsDescriptor} from "../Bots/BotsInput";
import {TaskExecutorType} from "../Core/Model";

export function undefinedIfEmpty(string: string): string | undefined {
    if (string.trim().length === 0) {
        return undefined
    }
    return string
}

export function toInteger(string: string): number | null {
    try {
        const value = Number(string)
        if (!Number.isInteger(value)) {
            return null
        }
        if (!Number.isFinite(value)) {
            return null
        }
        if (Number.isNaN(value)) {
            return null
        }
        return value
    } catch (e) {
        return null
    }
}

export function inflateBots(descriptor: BotsDescriptor): number[] {
    if (descriptor.type === 'bot') {
        return descriptor.bots.flatMap(range => {
            switch (range.type) {
                case 'id':
                    return [range.id]
                case 'range': {
                    let ids: number[] = []
                    for (let i = range.start; i < range.end; i++) {
                        ids.push(i)
                    }
                    return ids
                }
            }
        })
    } else if (descriptor.type === 'group') {
        return descriptor.groups.flatMap(group => {
            return group.bot_ids
        })
    }
    return []
}

export function inflateExecutors(descriptor: BotsDescriptor): [TaskExecutorType, number[]] {
    if (descriptor.type === 'bot') {
        return [TaskExecutorType.BOT, inflateBots(descriptor)]
    } else if (descriptor.type === 'group') {
        return [TaskExecutorType.GROUP, descriptor.groups.map(group => group.id)]
    }
    return [TaskExecutorType.BOT, []]
}