import {
    AddBotGroupRequest,
    AddBotGroupResponse,
    AddGroupBotsRequest,
    AddGroupBotsResponse,
    AddTaskRequest,
    BotCreateRequest,
    BotDeleteRequest,
    BotDeleteResponse,
    BotGroup,
    BotGroupDto,
    BotGroupList,
    BotGroupListDto,
    BotListInfo,
    BotListInfoDto,
    BotStatus,
    GetTask,
    GetTaskDto,
    RemoveBotGroupResponse,
    RemoveBotsFromGroupRequest,
    RemoveBotsFromGroupResponse,
    AddTaskResponse,
    SubTasks,
    SubTasksDto,
    TaskInterruptResponse,
    TaskListInfo,
    TaskListInfoDto,
    TaskSourceType,
    UpdateBotGroupRequest,
    UpdateBotGroupResponse,
    ProxiesList,
    ProxyListDto,
    DisableProxiesRequest,
    ProxyStatus,
    EnableProxiesResponse,
    EnableProxiesRequest,
    ChangeProxiesStatusResponse,
    DisableProxiesResponse,
    AddProxiesRequest,
    AddProxiesResponse, SetBotProxyRequest, SetBotProxyResponse
} from "./Model";

export class ApiError {
    response: Response | null
    message: string
    cause: Error | null

    constructor(response: Response | null, message: string, cause: Error | null) {
        this.response = response
        this.message = message
        this.cause = cause
    }
}

// eslint-disable-next-line no-unused-vars
type BodyFunction<T> = (response: Response) => Promise<T>

const JsonBody = function<T>(response: Response) {
    return response.json().then(e => e as T)
}

// eslint-disable-next-line no-unused-vars
const StaticResponse = function<T>(staticResponse: T): (response: Response) => Promise<T> {
    // eslint-disable-next-line no-unused-vars
    return async (_: Response) => staticResponse
}

export class Api {

    baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;
    }

    buildUrl(endpoint: string, query: object | null = null) {
        if (endpoint.startsWith('/')) {
            endpoint = endpoint.substring(1)
        }
        if (endpoint.endsWith('/')) {
            endpoint = endpoint.substring(0, endpoint.length - 1)
        }
        if (query === null) {
            return `${this.baseUrl}/${endpoint}`
        }
        const url = new URL(`${this.baseUrl}/${endpoint}`)
        Object.entries(query).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                for (const element of value) {
                    url.searchParams.append(key, element)
                }
            } else {
                url.searchParams.set(key, value)
            }
        });
        return url.toString();
    }

    async processResponse<T>(response: Response, bodyFn: BodyFunction<T>): Promise<T> {
        const status = response.status
        switch (status) {
            case 200: {
                try {
                    return bodyFn(response)
                } catch (e: any) {
                    throw new ApiError(response, 'Server response could not be processed. Contact support!', e)
                }
            }
            case 400: {
                throw new ApiError(response, 'Request failed because server did not recognize it. Contact support!', null)
            }
            case 500:
            case 502: {
                throw new ApiError(response, 'Server is unavailable or cannot process the request right now. Try again later.', null)
            }
            case 401:
            case 403: {
                throw new ApiError(response, 'You are not authorized to this request. Contact support!', null)
            }
            default: {
                throw new ApiError(response, `Unexpected server error occurred: HTTP ${status}`, null)
            }
        }
    }

    async processError<T>(error: Error): Promise<T> {
        if (error.message === 'Load failed' || error.message === 'Failed to fetch') {
            throw new ApiError(null, 'Server or internet connection is not available', error)
        }
        throw new ApiError(null, error.message, error)
    }

    async botsList(offset: number, limit: number, showStatus: BotStatus[], controller?: AbortController): Promise<BotListInfo> {
        return fetch(this.buildUrl('bots/', { offset: offset, limit: limit, show_status: showStatus }), {
            signal: controller?.signal
        })
            .then(e => this.processResponse<BotListInfoDto>(e, JsonBody))
            .then(e => new BotListInfo(e))
            .catch(e => this.processError(e))
    }

    async botsAdd(request: BotCreateRequest, controller?: AbortController): Promise<boolean> {
        return fetch(this.buildUrl('bots'), {
            signal: controller?.signal,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        }).then(e => this.processResponse(e, StaticResponse(true)))
            .catch(e => this.processError(e))
    }

    async tasksList(offset: number, limit: number, showSource: TaskSourceType[], controller?: AbortController): Promise<TaskListInfo> {
        return fetch(this.buildUrl('tasks/', { offset: offset, limit: limit, show_source: showSource }), {
            signal: controller?.signal
        })
            .then(e => this.processResponse<TaskListInfoDto>(e, JsonBody))
            .then(e => new TaskListInfo(e))
            .catch(e => this.processError(e))
    }

    async taskGet(taskId: number, controller?: AbortController): Promise<GetTask> {
        return fetch(this.buildUrl(`tasks/${taskId}`), {
            signal: controller?.signal
        }).then(e => this.processResponse<GetTaskDto>(e, JsonBody))
            .then(e => new GetTask(e))
            .catch(e => this.processError(e))
    }

    async taskSubtasksGet(taskId: number, offset: number, limit: number, controller?: AbortController): Promise<SubTasks> {
        return fetch(this.buildUrl(`tasks/${taskId}/sub-tasks/`, { offset: offset, limit: limit }), {
            signal: controller?.signal
        }).then(e => this.processResponse<SubTasksDto>(e, JsonBody))
            .then(e => new SubTasks(e))
            .catch(e => this.processError(e))
    }

    async taskInterrupt(taskId: number, controller?: AbortController): Promise<TaskInterruptResponse> {
        return fetch(this.buildUrl(`tasks/${taskId}/interrupt`), {
            method: 'POST',
            signal: controller?.signal
        }).then(e => this.processResponse<TaskInterruptResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async taskCreate(request: AddTaskRequest, controller?: AbortController): Promise<AddTaskResponse> {
        return fetch(this.buildUrl('tasks'), {
            signal: controller?.signal,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        }).then(e => this.processResponse<AddTaskResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async botsDelete(request: BotDeleteRequest, controller?: AbortController): Promise<BotDeleteResponse> {
        return fetch(this.buildUrl('bots'), {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<BotDeleteResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async groupsList(offset: number, limit: number, controller?: AbortController): Promise<BotGroupList> {
        return fetch(this.buildUrl('bot-groups/', { offset: offset, limit: limit }), {
            signal: controller?.signal
        })
            .then(e => this.processResponse<BotGroupListDto>(e, JsonBody))
            .then(e => new BotGroupList(e))
            .catch(e => this.processError(e))
    }

    async groupGet(groupId: number, controller?: AbortController): Promise<BotGroup> {
        return fetch(this.buildUrl(`bot-groups/${groupId}`), {
            signal: controller?.signal
        })
            .then(e => this.processResponse<BotGroupDto>(e, JsonBody))
            .then(e => new BotGroup(e))
            .catch(e => this.processError(e))
    }

    async groupsAdd(request: AddBotGroupRequest, controller?: AbortController): Promise<AddBotGroupResponse> {
        return fetch(this.buildUrl('bot-groups'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<AddBotGroupResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async groupsDelete(groupId: number, controller?: AbortController): Promise<RemoveBotGroupResponse> {
        return fetch(this.buildUrl(`bot-groups/${groupId}`), {
            method: 'DELETE',
            signal: controller?.signal
        }).then(e => this.processResponse<RemoveBotGroupResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async groupBotsList(groupId: number, offset: number, limit: number, controller?: AbortController): Promise<BotListInfo> {
        return fetch(this.buildUrl(`bot-groups/${groupId}/bots/`, { offset: offset, limit: limit }), {
            signal: controller?.signal
        })
            .then(e => this.processResponse<BotListInfoDto>(e, JsonBody))
            .then(e => new BotListInfo(e))
            .catch(e => this.processError(e))
    }

    async groupBotsAdd(groupId: number, request: AddGroupBotsRequest, controller?: AbortController): Promise<AddGroupBotsResponse> {
        return fetch(this.buildUrl(`bot-groups/${groupId}/bots`), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<AddGroupBotsResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async groupUpdate(groupId: number, request: UpdateBotGroupRequest, controller?: AbortController): Promise<UpdateBotGroupResponse> {
        return fetch(this.buildUrl(`bot-groups/${groupId}`), {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<UpdateBotGroupResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async groupBotsRemove(groupId: number, request: RemoveBotsFromGroupRequest, controller?: AbortController): Promise<RemoveBotsFromGroupResponse> {
        return fetch(this.buildUrl(`bot-groups/${groupId}/bots`), {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<RemoveBotsFromGroupResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async proxiesList(offset: number, limit: number, showStatus: ProxyStatus[], controller?: AbortController): Promise<ProxiesList> {
        return fetch(this.buildUrl('proxies/', { offset: offset, limit: limit, show_status: showStatus }), {
            signal: controller?.signal
        }).then(e => this.processResponse<ProxyListDto>(e, JsonBody))
            .then(e => new ProxiesList(e))
            .catch(e => this.processError(e))
    }

    async proxiesDisable(request: DisableProxiesRequest, controller?: AbortController): Promise<DisableProxiesResponse> {
        return fetch(this.buildUrl('proxies'), {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<DisableProxiesResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async proxiesEnable(request: EnableProxiesRequest, controller?: AbortController): Promise<EnableProxiesResponse> {
        return fetch(this.buildUrl('proxies'), {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<EnableProxiesResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async proxiesStatusChange(enable: EnableProxiesRequest, disable: DisableProxiesRequest, controller?: AbortController): Promise<ChangeProxiesStatusResponse> {
        const enableResponse: EnableProxiesResponse = enable.proxies.length > 0 ? await this.proxiesEnable(enable, controller) : { enabled_proxies: [] }
        const disableResponse: DisableProxiesResponse = disable.ids.length > 0 ? await this.proxiesDisable(disable, controller) : { disabled_proxies: [] }
        return {...enableResponse, ...disableResponse}
    }

    async proxiesAdd(request: AddProxiesRequest, controller?: AbortController): Promise<AddProxiesResponse> {
        return fetch(this.buildUrl('proxies'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<AddProxiesResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }

    async botsProxy(botId: number, request: SetBotProxyRequest, controller?: AbortController): Promise<SetBotProxyResponse> {
        return fetch(this.buildUrl(`bots/${botId}/proxy`), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            signal: controller?.signal
        }).then(e => this.processResponse<SetBotProxyResponse>(e, JsonBody))
            .catch(e => this.processError(e))
    }
}