import {BotDeleteResponse, BotInfo} from "../Core/Model";
import {Button, Modal, Spinner, Table} from "react-bootstrap";
import {CheckCircle, ExclamationCircle, X} from "react-bootstrap-icons";
import {useReducer} from "react";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useApi} from "../Core/ApiContextProvider";

export interface DeleteBotsDialogProps {
    bots: BotInfo[]
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
}

export interface DeleteBotRowProps {
    bot: BotInfo
}

const DeleteBotRow = ({ bot }: DeleteBotRowProps) => {
    return (
        <tr>
            <td className='bot-create-table-cell'>
                {bot.bot_id}
            </td>
            <td className='bot-create-table-cell'>
                {bot.login}
            </td>
            <td className='bot-create-table-cell'>
                {bot.session_key}
            </td>
        </tr>
    )
}

interface DeleteBotDialogState {
    delete: CallState<BotDeleteResponse>
}

type DeleteBotDialogEvent =
    {
        type: 'CALL_UPDATE',
        call: 'delete',
        data: CallUpdate
    };

function newState(): DeleteBotDialogState {
    return {
        delete: newCall(0)
    }
}

export const DeleteBotDialog = (props: DeleteBotsDialogProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: DeleteBotDialogState, event: DeleteBotDialogEvent) => {
        switch (event.type) {
            case 'CALL_UPDATE': {
                switch (event.call) {
                    case 'delete': {
                        return { ...state, delete: callDispatch(state.delete, event.data) }
                    }
                }
            }
        }
        return state
    }, newState());
    const onSubmit = () => {
        dispatchState({ type: 'CALL_UPDATE', call: 'delete', data: { type: 'LOADING', message: 'Deleting...' } })
        const request = { bot_ids: props.bots.map(e => e.bot_id) }
        const controller = new AbortController()
        api.botsDelete(request, controller)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'delete', data: { type: 'DATA', data: e }}))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'delete', data: { type: 'ERROR', error: e } }))
        return () => controller.abort()
    }
    const showSuccessMessage = !state.delete.loading && state.delete.error === null && state.delete.data !== null;
    const showErrorMessage = !state.delete.loading && state.delete.error !== null;
    const showLoading = state.delete.loading;
    return (
        <Modal show={true} size='lg'>
            <Modal.Header className='app-modal-header'>
                <Modal.Title>
                    <div className='app-modal-title'>
                        Delete bots
                    </div>
                </Modal.Title>
                <div className='app-modal-close' onClick={() => props.onClose(state.delete.data !== null)}>
                    <X size='2em' />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='modal-table'>
                        <Table size='sm'>
                            <thead>
                            <tr>
                                <th className='bot-create-table-cell'>ID</th>
                                <th className='bot-create-table-cell'>Login</th>
                                <th className='bot-create-table-cell'>Session Key</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.bots.map((bot) => <DeleteBotRow key={`delete-bot-${bot.bot_id}`} bot={bot} />)}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {showSuccessMessage ?
                    <div className='success-message' style={{ marginTop: '12px' }}>
                        <CheckCircle size='1.2em' color='green' />
                        <span>
                            {state.delete.data ? state.delete.data.removed_bot_ids.length : 0} of {props.bots.length} bots are successfully deleted!
                        </span>
                    </div> :
                    showErrorMessage ?
                        <div className='error-message' style={{ marginTop: '12px' }}>
                            <ExclamationCircle size='1.2em' color='red' />
                            <span>
                            Error occurred deleting bots: {state.delete.error ? state.delete.error.message : 'Unknown'}
                        </span>
                        </div> :
                        showLoading ?
                            <div className='loading-wrapper'>
                                <Spinner />
                                {state.delete.message !== null && <span className='loading-message'>
                        {state.delete.message}
                    </span>}
                            </div> :
                            <>
                                <Button onClick={() => onSubmit()} variant='danger' disabled={state.delete.loading}>
                                    Delete {props.bots.length} bot(s)
                                </Button>
                                <Button variant='outline-danger' disabled={state.delete.loading} onClick={() => props.onClose(false)}>
                                    Cancel
                                </Button>
                            </>
                }
            </Modal.Footer>
        </Modal>
    )
}