import {Badge, Button, Dropdown, Spinner, Table, Form} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/Bots.css'
import {useEffect, useReducer} from "react";
import {useApi} from "../Core/ApiContextProvider";
import {BotInfo, BotListInfo, BotStatus, BotStatuses, CreateBotCredentials} from "../Core/Model";
import {
    ArrowRepeat,
    Files,
    FolderPlus, Globe2,
    Plus,
    Trash, Trash3, XLg
} from "react-bootstrap-icons";
import {CreateBotDialog} from "./CreateBot";
import {callDispatch, CallState, CallUpdate, newCallWithMetadata} from "../Utils/Calls";
import {PaginationDirectionControls} from "../Utils/Pagination";
import {OpenBotFileDialog} from "./OpenBotFile";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {useCache} from "../Utils/UserCache";
import {DotsToggle} from "../Utils/DotsToggle";
import {DeleteBotDialog} from "./DeleteBotsDialog";
import {copyTextToClipboard} from "../Utils/ClipboardUtils";
import {BotChangeProxyModal} from "./BotChangeProxy";

interface BotListMetadata {
    selectedStatuses: BotStatus[]
}

interface BotDeleteState {
    enabled: boolean,
    selectedBots: BotInfo[]
}

interface BotListState {
    bots: CallState<BotListInfo, BotListMetadata>,
    createModal: { show: boolean, bots?: CreateBotCredentials[] },
    openBotFilesModal: { show: boolean },
    botDelete: BotDeleteState,
    deleteBots: BotInfo[] | null
    changeProxy: BotInfo | null
}

type BotListEvent =
    {
        type: 'CALL_UPDATE',
        call: 'bots',
        data: CallUpdate
    } |
    {
        type: 'SHOW_CREATE_MODAL',
        show: boolean,
        update: boolean,
        bots?: CreateBotCredentials[]
    } |
    {
        type: 'SHOW_OPEN_BOT_FILES_MODAL',
        show: boolean
    } |
    {
        type: 'ENABLE_BOT_DELETE'
    } |
    {
        type: 'DISABLE_BOT_DELETE'
    } |
    {
        type: 'TOGGLE_BOT_DELETE',
        bot: BotInfo
    } |
    {
        type: 'DELETE_BOTS',
        bots: BotInfo[]
    } |
    {
        type: 'CLOSE_DELETE_BOTS',
        update: boolean
    } |
    {
        type: 'CHANGE_PROXY',
        bot: BotInfo
    } |
    {
        type: 'CLOSE_CHANGE_PROXY',
        update: boolean
    }

function newState(limit: number, statuses: BotStatus[]): BotListState {
    return {
        bots: newCallWithMetadata({ selectedStatuses: statuses }, limit),
        createModal: { show: false },
        openBotFilesModal: { show: false },
        botDelete: { enabled: false, selectedBots: [] },
        deleteBots: null,
        changeProxy: null
    }
}

export const BotList = () => {
    const cache = useCache()
    const api = useApi()
    const [state, dispatchState] = useReducer((state: BotListState, event: BotListEvent) => {
        if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case "bots":
                    return { ...state, bots: callDispatch(state.bots, event.data) }
            }
        } else if (event.type === 'SHOW_CREATE_MODAL') {
            return { ...state, openBotFilesModal: { show: false }, createModal: { show: event.show, bots: event.bots }, bots: { ...state.bots, refreshIndicator: event.update ? !state.bots.refreshIndicator : state.bots.refreshIndicator } }
        } else if (event.type === 'SHOW_OPEN_BOT_FILES_MODAL') {
            return { ...state, openBotFilesModal: { show: event.show }, createModal: { show: false, bots: undefined } }
        } else if (event.type === 'ENABLE_BOT_DELETE') {
            return { ...state, botDelete: { enabled: true, selectedBots: [] } }
        } else if (event.type === 'DISABLE_BOT_DELETE') {
            return { ...state, botDelete: { enabled: false, selectedBots: [] } }
        } else if (event.type === 'TOGGLE_BOT_DELETE') {
            if (state.botDelete.enabled) {
                let bots = state.botDelete.selectedBots
                if (bots.find(e => e.bot_id === event.bot.bot_id) !== undefined) {
                    bots = bots.filter(e => e.bot_id !== event.bot.bot_id)
                } else {
                    bots = [...bots, event.bot]
                }
                return { ...state, botDelete: { ...state.botDelete, selectedBots: bots } }
            }
            return { ...state }
        } else if (event.type === 'CLOSE_DELETE_BOTS') {
            return { ...state, deleteBots: null, bots: { ...state.bots, refreshIndicator: event.update ? !state.bots.refreshIndicator : state.bots.refreshIndicator } }
        } else if (event.type === 'DELETE_BOTS') {
            return { ...state, deleteBots: event.bots, botDelete: { enabled: false, selectedBots: [] } }
        } else if (event.type === 'CHANGE_PROXY') {
            return { ...state, changeProxy: event.bot }
        }  else if (event.type === 'CLOSE_CHANGE_PROXY') {
            const refreshIndicator = event.update ? !state.bots.refreshIndicator : state.bots.refreshIndicator;
            return { ...state, changeProxy: null, bots: { ...state.bots, refreshIndicator: refreshIndicator } }
        }
        return state
    }, newState(cache.number('bots_limit', 10), cache.json('bots_statuses', BotStatuses)));
    const onStatusSelect = (status: BotStatus, isSelected: boolean) => {
        const currentStatuses = state.bots.metadata.selectedStatuses
        const newSelectedStatuses = isSelected ?
            currentStatuses.filter(e => e !== status) :
            [...currentStatuses, status]
        const newMetadata = { ...state.bots.metadata, selectedStatuses: cache.setJson('bots_statuses', newSelectedStatuses) }
        dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'SET_METADATA', metadata: newMetadata }})
    }
    const onLimitUpdate = (limit: number) => {
        dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'LIMIT', limit: cache.setNumber('bots_limit', limit) } })
    }
    const onBotDeleteTrigger = () => {
        if (state.botDelete.enabled && state.botDelete.selectedBots.length > 0) {
            dispatchState({ type: 'DELETE_BOTS', bots: state.botDelete.selectedBots })
        } else {
            dispatchState({ type: 'ENABLE_BOT_DELETE' })
        }
    }
    useEffect(() => {
        const controller = new AbortController()
        dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'LOADING', message: 'Bots are loading...' } })
        api.botsList(state.bots.pagination.offset, state.bots.pagination.limit, state.bots.metadata.selectedStatuses, controller)
            .then(botList => dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'DATA', data: botList } }))
            .catch(error => dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'ERROR', error: error } }))
        return () => controller.abort()
    }, [state.bots.refreshIndicator, state.bots.pagination.limit, state.bots.pagination.offset, state.bots.metadata.selectedStatuses])
    return (
        <>
            <CreateBotDialog size='lg' show={state.createModal.show} initialBots={state.createModal.bots} onClose={(update) => dispatchState({ type: 'SHOW_CREATE_MODAL', show: false, update: update })} />
            <OpenBotFileDialog show={state.openBotFilesModal.show} onClose={() => dispatchState({ type: 'SHOW_OPEN_BOT_FILES_MODAL', show: false })} onContinue={(bots) => dispatchState({ type: 'SHOW_CREATE_MODAL', show: true, update: false, bots: bots })} />
            {state.deleteBots && <DeleteBotDialog bots={state.deleteBots} onClose={(update) => dispatchState({ type: 'CLOSE_DELETE_BOTS', update: update })} />}
            {state.changeProxy && <BotChangeProxyModal bot={state.changeProxy} onClose={(changed) => dispatchState({ type: 'CLOSE_CHANGE_PROXY', update: changed })} />}
            <div className='table-wrapper'>
                <div className='table-toolbar'>
                    <Button disabled={state.bots.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'REFRESH' } })}>
                        <div className='icon-button'>
                            <ArrowRepeat size='1.2em' title='Refresh' />
                        </div>
                    </Button>
                    <Button disabled={state.bots.loading} onClick={() => dispatchState({ type: 'SHOW_CREATE_MODAL', show: true, update: false })}>
                        <div className='icon-button'>
                            <Plus size='1.2em' />
                            <span>Add bot</span>
                        </div>
                    </Button>
                    <Button disabled={state.bots.loading} onClick={() => dispatchState({ type: 'SHOW_OPEN_BOT_FILES_MODAL', show: true })}>
                        <div className='icon-button'>
                            <FolderPlus />
                            <span>Add bots from file</span>
                        </div>
                    </Button>
                    <Dropdown autoClose={'outside'}>
                        <Dropdown.Toggle>Filter: {
                            state.bots.metadata.selectedStatuses.length === 0 ?
                                'All statuses' :
                                state.bots.metadata.selectedStatuses.length === 1 ?
                                BotStatus[state.bots.metadata.selectedStatuses[0]] :
                                `${state.bots.metadata.selectedStatuses.length} statuses`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            {
                                BotStatuses.map(key => {
                                    const isSelected = state.bots.metadata.selectedStatuses.includes(key)
                                    return (
                                        <Dropdown.Item eventKey={key} key={`status-${key}`} value={key} onClick={() => onStatusSelect(key, isSelected)}>
                                            <Form.Check inline checked={isSelected} label={BotStatus[key]} readOnly/>
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant={state.botDelete.enabled ? 'danger' : 'outline-danger'} disabled={state.bots.loading || (state.botDelete.enabled && state.botDelete.selectedBots.length === 0)} onClick={onBotDeleteTrigger}>
                        <div className='icon-button'>
                            <Trash3 />
                            {state.botDelete.enabled ? <span>Delete {state.botDelete.selectedBots.length} bots</span> : <span>Delete bots</span>}
                        </div>
                    </Button>
                    {state.botDelete.enabled ? <Button variant='outline-danger' disabled={state.bots.loading} onClick={() => dispatchState({ type: 'DISABLE_BOT_DELETE' })}>
                        <div className='icon-button'>
                            <XLg />
                            <span>Cancel delete</span>
                        </div>
                    </Button> : null}
                </div>
                <div className='overflow-table-wrapper'>
                    <Table striped hover className='full-width'>
                        <thead>
                            <tr>
                                <th>
                                    ID
                                </th>
                                <th>
                                    Created
                                </th>
                                <th>
                                   Login
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Last Update
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {state.bots.data !== null && state.bots.data.bots.length > 0 ?
                            state.bots.data.bots.map(bot => {
                                return (
                                    <tr key={bot.bot_id}>
                                        <td>
                                            {bot.bot_id}
                                        </td>
                                        <td>
                                            <SimpleTooltip tooltip={(
                                                <div>UTC: {bot.create_date.utc}</div>
                                            )}>
                                                {bot.create_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td>
                                            {bot.login}
                                        </td>
                                        <td>
                                            <SimpleTooltip tooltip={<div>Status ID: {bot.status}</div>}>
                                                <Badge bg={bot.status_bg}>{bot.status_name}</Badge>
                                            </SimpleTooltip>
                                        </td>
                                        <td>
                                            <SimpleTooltip tooltip={(
                                                <div>UTC: {bot.update_date.utc}</div>
                                            )}>
                                                {bot.update_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td>
                                            {state.botDelete.enabled ?
                                                <Button onClick={() => dispatchState({ type: 'TOGGLE_BOT_DELETE', bot: bot })} variant={state.botDelete.selectedBots.find(e => e.bot_id === bot.bot_id) ? 'danger' : 'outline-danger'} size='sm'><Trash /></Button>
                                                :
                                                <Dropdown>
                                                    <Dropdown.Toggle as={DotsToggle}></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.ItemText>
                                                            Bot {bot.bot_id}
                                                        </Dropdown.ItemText>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Header>Session Key</Dropdown.Header>
                                                        <Dropdown.Item disabled={!bot.session_key} onClick={() => bot.session_key ? copyTextToClipboard(bot.session_key) : null}>
                                                            <div className='icon-button'>
                                                                <Files /> <span>{bot.session_key ?? "N/A"}</span>
                                                            </div>
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Header>
                                                            {bot.proxy ? `Proxy ${bot.proxy.id}` : 'No proxy set'}
                                                        </Dropdown.Header>
                                                        {bot.proxy && <Dropdown.Item onClick={() => bot.proxy ? copyTextToClipboard(bot.proxy.data) : null}>
                                                            <Globe2 /> {bot.proxy?.data ?? "N/A"}
                                                        </Dropdown.Item>}
                                                        <Dropdown.Item onClick={() => dispatchState({ type: 'CHANGE_PROXY', bot: bot })}>
                                                            Change proxy
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => dispatchState({ type: 'DELETE_BOTS', bots: [ bot ] })}>
                                                            <div className='icon-button text-danger'>
                                                                <Trash /> <span>Delete</span>
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </td>
                                    </tr>
                                )
                            }) : null}
                        {state.bots.data !== null && state.bots.data.bots.length === 0 ?
                            <tr>
                                <td colSpan={7} className='center-text'>
                                    Empty list of bots
                                </td>
                            </tr> : null}
                        {state.bots.error !== null && !state.bots.loading ?
                            <tr>
                                <td colSpan={7} className='center-text'>
                                    <div>Error occurred loading bots</div>
                                    <div>{state.bots.error.message}</div>
                                </td>
                            </tr>
                            : null
                        }
                        </tbody>
                    </Table>
                </div>
                <PaginationDirectionControls
                    call={state.bots}
                    show={!state.bots.loading}
                    totalCountFn={(data) => data.total_count}
                    onPaginationControl={(d) => dispatchState({ type: 'CALL_UPDATE', call: 'bots', data: { type: 'PAGINATION', direction: d } })}
                    onPaginationLimit={onLimitUpdate} />
                {state.bots.loading ? <div className='loading-wrapper'>
                    <Spinner />
                    {state.bots.message !== null && <span className='loading-message'>
                        {state.bots.message}
                    </span>}
                </div> : null}
            </div>
        </>
    )
}