import './Style/App.css';
import {ApiProvider} from "../Core/ApiContextProvider";
import {Environment} from "../Core/Environment";
import {
    BrowserRouter,
    Link,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import {Dashboard} from '../Dashboard/Dashboard';
import {Container, Nav, Navbar} from "react-bootstrap";
import {TaskDetailsPage} from "../Tasks/TaskDetailsPage";
import {UserCacheProvider} from "../Utils/UserCache";
import {GroupDetailsPage} from "../Groups/GroupDetailsPage";

export const App = () => {
  return (
      <ApiProvider baseUrl={`${Environment.BASE_URL}`}>
          <UserCacheProvider>
              <BrowserRouter basename={Environment.BASENAME}>
                  <Navbar bg="dark" variant="dark">
                      <Container>
                          <Navbar.Brand as={Link} to='/'>
                              <span className="no-select">
                              Instagram Upload
                              </span>
                          </Navbar.Brand>
                          <Nav className="me-auto">
                              <Nav.Link as={Link} to='/dashboard'>
                                  Dashboard
                              </Nav.Link>
                          </Nav>
                      </Container>
                  </Navbar>
                  <div className='app-root'>
                      <Container className='app-container'>
                          <Routes>
                              <Route path='/' element={<Navigate to='/dashboard' />} />
                              <Route path='/dashboard' element={<Dashboard />} />
                              <Route path='/task/:taskId' element={<TaskDetailsPage />} />
                              <Route path='/bot-group/:groupId' element={<GroupDetailsPage />} />
                          </Routes>
                      </Container>
                  </div>
              </BrowserRouter>
          </UserCacheProvider>
      </ApiProvider>
  );
}