import {ChangeEvent, createContext, ReactNode, useContext, useState} from "react";
import {Badge, Dropdown, Form, FormControl, InputGroup} from "react-bootstrap";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {CheckLg, XLg} from "react-bootstrap-icons";
import {toInteger} from "../Utils/Utils";
import {FieldState} from "../Utils/FieldState";
import {MultiselectPaginatedDropdown} from "../Utils/MultiselectPaginatedDropdown";
import {useApi} from "../Core/ApiContextProvider";
import {BotGroup, TaskExecutorType} from "../Core/Model";

export type BotsDescriptor = { type: 'bot', bots: BotRange[] } | { type: 'group', groups: BotGroup[] }
type BotsInputValidity = { valid: false, message: string } | { valid: true, message: string, data: BotsDescriptor }

export interface BotsInputState {
    botsValid: BotsInputValidity
    // eslint-disable-next-line no-unused-vars
    setBotsValid: (newState: BotsInputValidity) => void
}

export const BotsInputContext = createContext<BotsInputState | null>(null)

export const useBotsInput = () => {
    const [botsValid, setBotsValid] = useState<FieldState<BotRange[]>>({ valid: false, message: 'You need to select at least one bot' })
    return { botsValid, setBotsValid } as BotsInputState
}

const useBotsInputContext = () => useContext(BotsInputContext) ?? useBotsInput()

export const BotsInputProvider = (props: { value: BotsInputState, children: ReactNode }) => {
    return (
        <BotsInputContext.Provider value={props.value}>
            {props.children}
        </BotsInputContext.Provider>
    )
}

export type BotRange =
    { type: 'id', id: number } | { type: 'range', start: number, end: number }

const GroupItem = (props: { group: BotGroup }) => {
    return (
        <div className='cursor-pointer' style={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
            <div>#{props.group.id}</div>
            <div>
                <div >{props.group.name}</div>
                <Badge bg={props.group.variant}>{props.group.status_name}</Badge>
            </div>
        </div>
    )
}

export const BotsInput = () => {
    const api = useApi()
    const [type, setType] = useState<TaskExecutorType>(TaskExecutorType.BOT)
    const {botsValid, setBotsValid} = useBotsInputContext()
    const fetchGroups = (limit: number, offset: number) => {
        return api.groupsList(offset, limit)
            .then(e => { return { data: e.groups, total_count: e.total_count } })
    }
    const onGroupsChange = (groups: BotGroup[]) => {
        if (groups.length === 0) {
            setBotsValid({ valid: false, message: 'You need to select at least one group' })
            return
        }
        setBotsValid({ valid: true, message: 'Ok', data: { type: 'group', groups: groups } })
    }
    const onTypeSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            if (eventKey === 'bot') {
                setType(TaskExecutorType.BOT)
            } else if (eventKey === 'group') {
                setType(TaskExecutorType.GROUP)
            }
        }
    }
    const onBotsChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim()
        if (value.length === 0) {
            setBotsValid({ valid: false, message: 'You need to select at least one bot' })
            return
        }
        const segments = value.split(',').map(e => e.trim())
        let bots: BotRange[] = []
        for (let index = 0; index < segments.length; index++) {
            const segment = segments[index]
            if (segment.length === 0) {
                continue
            }
            const separationIndex = segment.indexOf('-')
            if (separationIndex >= 0) {
                const startValue = segment.substring(0, separationIndex).trim()
                const endValue = segment.substring(separationIndex + 1, segment.length).trim()
                if (startValue.length === 0) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Value before '-' is empty` })
                    return
                }
                if (endValue.length === 0) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Value after '-' is empty` })
                    return
                }
                const start = toInteger(startValue)
                const end = toInteger(endValue)
                if (start === null) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Value before '-' is not a valid integer` })
                    return
                }
                if (end === null) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Value after '-' is not a valid integer` })
                    return
                }
                if (start < 0) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Value before '-' cannot be negative` })
                    return
                }
                if (end < 0) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Value after '-' cannot be negative` })
                    return
                }
                if (start > end) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Start value must be less or equal to end value` })
                    return
                }
                const rangeLimit = 1000
                if (end - start > rangeLimit) {
                    setBotsValid({ valid: false, message: `Range ${index + 1}: Maximum allowed range is ${rangeLimit} < ${end - start}` })
                    return
                }
                bots.push({ type: 'range', start: start, end: end })
            } else {
                const botId = toInteger(segment)
                if (botId === null) {
                    setBotsValid({ valid: false, message: `Value ${index + 1}: Not a valid integer` })
                    return
                }
                bots.push({ type: 'id', id: botId })
            }
        }
        setBotsValid({ valid: true, message: `Ok. ${bots.length} ranges`, data: { type: 'bot', bots: bots } })
    }
    return (
        <>
            {type === TaskExecutorType.BOT ? <>
                <Form.Text>
                    You can enter Bot IDs separated by comma. For specific ID range use inclusive start ID [dash] exclusive end ID. All spaces are omitted.
                    Duplicated bots are skipped.
                    Not existing bots are skipped.
                </Form.Text><br />
                <Form.Text>
                    Example: 1,2,10-25,30
                </Form.Text>
            </>: <>
                <Form.Text>You can select one or multiple groups to schedule task to all bots from selected groups.</Form.Text>
            </>}
            <InputGroup style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch'}}>
                <Dropdown onSelect={onTypeSelect}>
                    <Dropdown.Toggle className='cursor-pointer' as={InputGroup.Text}>{type === TaskExecutorType.BOT ? `Bots` : `Groups`}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey='bot'>Bots</Dropdown.Item>
                        <Dropdown.Item eventKey='group'>Groups</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {type === TaskExecutorType.BOT ?
                    <FormControl type='text' placeholder='ex. 1,2,10-25,30' onChange={onBotsChange} /> :
                    <MultiselectPaginatedDropdown
                        empty={`Select group`}
                        single={e => `Group ${e.name}`}
                        multiple={es => `${es.length} groups`}
                        fetch={fetchGroups}
                        itemKey={(e: BotGroup) => e.id}
                        label={item => <GroupItem group={item} />}
                        onChange={onGroupsChange} />
                }
                <InputGroup.Text>
                    <SimpleTooltip tooltip={botsValid.message}>
                        {botsValid.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
        </>
    )
}