import {ProxyData} from "../Core/Model";
import React, {ChangeEvent, ClipboardEvent, FormEvent, KeyboardEvent, MutableRefObject, useRef, useState} from "react";
import {undefinedIfEmpty} from "../Utils/Utils";
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {CheckLg, PlusLg, XLg} from "react-bootstrap-icons";
import {SimpleTooltip} from "../Utils/SimpleTooltip";

export interface ProxyInputProps {
    enableAdd: boolean
    readonly?: boolean
    // eslint-disable-next-line no-unused-vars
    onAdd?: (data: ProxyData) => boolean
}
export const ProxyInput = (props: ProxyInputProps) => {
    const readonly = props.readonly !== undefined ? props.readonly : false
    const [errors, setErrors] = useState<string[]>([])
    const hostRef = useRef<HTMLInputElement | null>()
    const portRef = useRef<HTMLInputElement | null>()
    const usernameRef = useRef<HTMLInputElement | null>()
    const passwordRef = useRef<HTMLInputElement | null>()

    // eslint-disable-next-line no-unused-vars
    const validateState = (host: string, port: string, username: string, password: string): string[] => {
        const errors: string[] = []
        if (host.length < 1) {
            errors.push('Host must be specified')
        }
        const numberPort = Number(port)
        if (Number.isNaN(numberPort) || numberPort <= 0 || numberPort >= 0xFFFF) {
            errors.push('Port must be valid port number')
        }
        return errors;
    }

    const updateDisabledState = (host: string, port: string, username: string, password: string) => {
        setErrors(validateState(host, port, username, password))
    }

    const onFieldChange = (next: MutableRefObject<HTMLInputElement | null | undefined>) => (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.endsWith(":")) {
            event.target.value = event.target.value.substring(0, event.target.value.length - 1)
            next.current?.focus()
            event.stopPropagation()
        }
    }

    const onFieldKey = (prev?: MutableRefObject<HTMLInputElement | null | undefined>) => (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace') {
            const target = event.target as HTMLInputElement
            if (target.value.length === 0) {
                prev?.current?.focus()
            }
        }
    }

    const onHostPaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const data = event.clipboardData.getData('text/plain')
        if (data !== undefined && data !== null) {
            const currentHost = hostRef.current;
            const currentPort = portRef.current
            const currentUsername = usernameRef.current
            const currentPassword = passwordRef.current

            const params = data.split(":")
            if (params.length > 1) { // sorry, no colons in password and no IPv6 here
                const host = params[0]
                const port = params[1]
                const username = params.length > 2 ? params[2] : '';
                const password = params.length > 3 ? params[3] : '';
                event.preventDefault()

                if (currentHost !== undefined &&
                    currentHost !== null &&
                    currentPort != undefined &&
                    currentUsername !== undefined &&
                    currentUsername !== null &&
                    currentPassword !== undefined &&
                    currentPassword !== null) {
                    currentHost.value = host
                    currentPort.value = port
                    currentUsername.value = username
                    currentPassword.value = password
                    if (password.length > 0) {
                        currentPassword.focus()
                    } else if (username.length > 0) {
                        currentUsername.focus()
                    } else {
                        currentPort.focus()
                    }
                    updateDisabledState(currentHost.value, currentPort.value, currentUsername.value, currentPassword.value)
                }
            } else if (currentHost !== undefined &&
                currentHost !== null &&
                currentPort != undefined &&
                currentUsername !== undefined &&
                currentUsername !== null &&
                currentPassword !== undefined &&
                currentPassword !== null) {
                updateDisabledState(currentHost.value, currentPort.value, currentUsername.value, currentPassword.value)
            }
        }
    }

    const onFormChange = (event: ChangeEvent<HTMLFormElement>) => {
        const host: HTMLInputElement = event.target.form[0] as HTMLInputElement
        const port: HTMLInputElement = event.target.form[1] as HTMLInputElement
        const username: HTMLInputElement = event.target.form[2] as HTMLInputElement
        const password: HTMLInputElement = event.target.form[3] as HTMLInputElement
        updateDisabledState(host.value, port.value, username.value, password.value)
    }

    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!props.enableAdd) {
            return
        }
        const host: HTMLInputElement = (event.target as HTMLFormElement)[0] as HTMLInputElement
        const port: HTMLInputElement = (event.target as HTMLFormElement)[1] as HTMLInputElement
        const username: HTMLInputElement = (event.target as HTMLFormElement)[2] as HTMLInputElement
        const password: HTMLInputElement = (event.target as HTMLFormElement)[3] as HTMLInputElement
        const validateErrors = validateState(host.value, port.value, username.value, password.value)
        if (validateErrors.length === 0) {
            const onAdd = props.onAdd
            if (onAdd !== undefined && onAdd !== null) {
                const data = { host: host.value, port: Number(port.value), username: undefinedIfEmpty(username.value), password: undefinedIfEmpty(password.value) }
                const clearInput = onAdd(data)
                if (clearInput) {
                    const currentHost = hostRef.current;
                    const currentPort = portRef.current
                    const currentUsername = usernameRef.current
                    const currentPassword = passwordRef.current
                    if (currentHost !== undefined &&
                        currentHost !== null &&
                        currentPort != undefined &&
                        currentUsername !== undefined &&
                        currentUsername !== null &&
                        currentPassword !== undefined &&
                        currentPassword !== null) {
                        currentHost.value = ""
                        currentPort.value = ""
                        currentUsername.value = ""
                        currentPassword.value = ""
                        currentHost.focus()
                        updateDisabledState(currentHost.value, currentPort.value, currentUsername.value, currentPassword.value)
                    }
                }
            }
        }
    }

    const onFormKey = (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLFormElement).submit()
        }
    }

    const getSubmit = () => {
        const tooltip = errors.length > 0 ? errors.map((e, idx) => <div key={`error-${idx}`}>{e}</div>) : 'Ok'
        return props.enableAdd ?
            <Button variant={errors.length > 0 ? 'danger' : 'success'} type='submit'>
                <SimpleTooltip tooltip={tooltip}>
                    {errors.length > 0 ? <XLg /> : <PlusLg/>}
                </SimpleTooltip></Button> :
            <InputGroup.Text>
                <SimpleTooltip tooltip={tooltip}>{errors.length > 0 ? <XLg color='red'/> :
                    <CheckLg color='green'/>}</SimpleTooltip>
            </InputGroup.Text>
    }

    return (
        <form onKeyDown={onFormKey} onSubmit={onFormSubmit} onChange={onFormChange}>
            <InputGroup className='proxy-input'>
                <FormControl disabled={readonly} name='host' onPaste={onHostPaste} onChange={onFieldChange(portRef)} ref={hostRef as React.RefObject<any>} placeholder='Host'></FormControl>
                <InputGroup.Text>:</InputGroup.Text>
                <FormControl disabled={readonly} name='port' onChange={onFieldChange(usernameRef)} onKeyDown={onFieldKey(hostRef)} ref={portRef as React.RefObject<any>} placeholder='Port'></FormControl>
                <InputGroup.Text>:</InputGroup.Text>
                <FormControl disabled={readonly} name='username' onChange={onFieldChange(passwordRef)} onKeyDown={onFieldKey(portRef)} ref={usernameRef as React.RefObject<any>} placeholder='User (optional)'></FormControl>
                <InputGroup.Text>:</InputGroup.Text>
                <FormControl disabled={readonly} name='password' onKeyDown={onFieldKey(usernameRef)} ref={passwordRef as React.RefObject<any>} placeholder='Password (optional)'></FormControl>
                {getSubmit()}
            </InputGroup>
        </form>
    )
}