import {
    ChangeProxiesStatusResponse,
    ProxyInfo
} from "../Core/Model";
import {Button, Modal, Spinner, Table} from "react-bootstrap";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {useApi} from "../Core/ApiContextProvider";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useReducer} from "react";
import {BlockTitle} from "../Utils/BlockTitle";
import {ExclamationCircle} from "react-bootstrap-icons";

export interface DisableProxiesModalProps {
    disable: ProxyInfo[]
    enable: ProxyInfo[]
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
}

interface ChangeProxyStatusState {
    change: CallState<ChangeProxiesStatusResponse>
}

type ChangeProxyStatusEvent = {
    type: 'CALL_UPDATE',
    call: 'change',
    update: CallUpdate
}

function newState(): ChangeProxyStatusState {
    return {
        change: newCall()
    }
}

export const ChangeProxyStatusModal = (props: DisableProxiesModalProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: ChangeProxyStatusState, event: ChangeProxyStatusEvent) => {
        switch (event.type) {
            case "CALL_UPDATE": {
                switch (event.call) {
                    case "change":
                        return { ...state, change: callDispatch(state.change, event.update) }
                }
                break;
            }
        }
        return state;
    }, newState())
    const onProceed = () => {
        dispatchState({ type: 'CALL_UPDATE', call: 'change', update: { type: 'LOADING', message: 'Changes proxies status...' } })
        api.proxiesStatusChange({ proxies: props.enable.map(e => e.id) }, { ids: props.disable.map(e => e.id) })
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'change', update: { type: 'DATA', data: e } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'change', update: { type: 'ERROR', error: e } }))
    }
    const onClose = () => {
        // currently, since there are two requests to perform 'change' operation
        // only one of the requests might fail so changed applied by succeeded request
        // not being rolled back, so list must be refreshed even when error has occurred
        props.onClose(state.change.data !== null || state.change.error !== null)
    }
    const getContent = () => {
        if (state.change.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner/>
                    {state.change.message !== null && <span className='loading-message'>
                        {state.change.message}
                   </span>}
                </div>
            )
        }

        if (state.change.error) {
            return (
                <div className='error-message'>
                    <ExclamationCircle size='1.2em' color='red' />
                    <div>
                        <div>Error occurred changing status of proxies: {state.change.error.message}</div>
                        <div>Some proxies may be modified due this call.</div>
                    </div>
                </div>
            )
        }

        if (state.change.data) {
            return (
                <div className='success-message'>
                    <div>
                        <div>Proxies status successfully changed.</div>
                        {props.enable.length > 0 && <div>{state.change.data.enabled_proxies.length} proxies has been <span className='text-success'>enabled</span></div>}
                        {props.disable.length > 0 && <div>{state.change.data.disabled_proxies.length} proxies has been <span className='text-danger'>disabled</span></div>}
                    </div>
                </div>
            )
        }
        const toBeDisabled = () => {
            if (props.disable.length > 0) {
                return (
                    <>
                        <BlockTitle title={'To be disabled'} />
                        <Table size='sm'>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Created</th>
                                <th>Data</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.disable.map(proxy => {
                                return (
                                    <tr key={`proxy-disable-${proxy.id}`}>
                                        <td>
                                            {proxy.id}
                                        </td>
                                        <td>
                                            <SimpleTooltip tooltip={<div>UTC: {proxy.create_date.utc}</div>}>
                                                {proxy.create_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td>
                                            {proxy.data}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </>
                )
            }
            return null
        }
        const toBeEnabled = () => {
            if (props.enable.length > 0) {
                return (
                    <>
                        <BlockTitle title={'To be enabled'} />
                        <Table size='sm'>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Created</th>
                                <th>Data</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.enable.map(proxy => {
                                return (
                                    <tr key={`proxy-disable-${proxy.id}`}>
                                        <td>
                                            {proxy.id}
                                        </td>
                                        <td>
                                            <SimpleTooltip tooltip={<div>UTC: {proxy.create_date.utc}</div>}>
                                                {proxy.create_date.local}
                                            </SimpleTooltip>
                                        </td>
                                        <td>
                                            {proxy.data}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </>
                )
            }
            return null
        }
        return (<>{toBeDisabled()}{toBeEnabled()}</>)
    }

    const getFooter = () => {
        if (state.change.data && !state.change.loading) {
            return (
                <Button variant='primary' onClick={onClose}>
                    Close
                </Button>
            )
        }
        if (state.change.error && !state.change.loading) {
            return (
                <>
                    <Button variant='primary' onClick={onProceed}>
                        Try again
                    </Button>
                    <Button variant='outline-primary' onClick={onClose}>
                        Close
                    </Button>
                </>
            )
        }
        return (
            <>
                <Button variant='primary' onClick={onProceed} disabled={state.change.loading}>
                    Proceed
                </Button>
                <Button variant='outline-danger' onClick={onClose} disabled={state.change.loading}>
                    Cancel
                </Button>
            </>
        )
    }

    return (
        <Modal show={true} size='lg' fullscreen='lg-down'>
            <Modal.Header>
                <Modal.Title>Change status of {props.enable.length + props.disable.length} proxies</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent()}
            </Modal.Body>
            <Modal.Footer>
                {getFooter()}
            </Modal.Footer>
        </Modal>
    )
}