import {Button, FormControl, Modal, Table} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import {CreateBotCredentials} from "../Core/Model";
import {ChangeEvent, useReducer} from "react";
import {BotFile, BotFileEvent, BotFileState, newState as newFileState, dispatchState as dispatchFileState} from "./BotFile";

export interface OpenBotFileDialogProps {
    show: boolean,
    // eslint-disable-next-line no-unused-vars
    onClose: () => void,
    // eslint-disable-next-line no-unused-vars
    onContinue: (bots: CreateBotCredentials[]) => void
}

interface OpenFile {
    file: File,
    state: BotFileState
}

type OpenBotFileDialogTotalState = 'empty' | 'valid' | 'errored' | 'loading'

interface OpenBotFileDialogState {
    files: OpenFile[],
    totalState: OpenBotFileDialogTotalState,
    totalBots: CreateBotCredentials[]
}

type OpenBotFileDialogEvent =
    {
        type: 'OPEN_FILE',
        file: File
    } |
    {
        type: 'FILE_EVENT',
        index: number,
        event: BotFileEvent
    } |
    {
        type: 'REMOVE_FILE',
        index: number
    } |
    {
        type: 'RESET'
    }

function newState(): OpenBotFileDialogState {
    return {
        files: [],
        totalState: 'empty',
        totalBots: []
    }
}

function calculateTotalBots(files: OpenFile[]): CreateBotCredentials[] {
    return files.flatMap(file => {
        if (file.state.fileState.type === 'success') {
            return file.state.fileState.bots
        }
        return []
    })
}

function calculateErrors(files: OpenFile[]): OpenBotFileDialogTotalState {
    if (files.length === 0) {
        return 'empty'
    }
    for (const { state } of files) {
        if (state.fileState.type === 'error') {
            return 'errored'
        }
        if (state.fileState.type === 'loading') {
            return 'loading'
        }
    }
    return 'valid'
}

export const OpenBotFileDialog = (props: OpenBotFileDialogProps) => {
    const [state, dispatchState] = useReducer((state: OpenBotFileDialogState, event: OpenBotFileDialogEvent) => {
        switch (event.type) {
            case 'OPEN_FILE': {
                const files = [...state.files, {file: event.file, state: newFileState()}]
                return { ...state, files: files, totalState: calculateErrors(files), totalBots: calculateTotalBots(files) }
            }
            case 'REMOVE_FILE': {
                const files = state.files.filter((_, index) => index !== event.index)
                return { ...state, files: files, totalState: calculateErrors(files), totalBots: calculateTotalBots(files) }
            }
            case 'FILE_EVENT': {
                let file = state.files[event.index]
                let files = [...state.files]
                files[event.index] = { ...file, state: dispatchFileState(file.state, event.event) }
                return { ...state, files: files, totalState: calculateErrors(files), totalBots: calculateTotalBots(files) }
            }
            case 'RESET': {
                return newState();
            }
        }
        return state
    }, newState());
    const onClose = () => {
        dispatchState({ type: 'RESET' })
        props.onClose()
    }
    const onContinue = (bots: CreateBotCredentials[]) => {
        props.onContinue(bots)
    }
    const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== undefined && event.target.files !== null && event.target.files.length > 0) {
            const file = event.target.files[0]
            dispatchState({ type: 'OPEN_FILE', file: file })
            event.target.value = ''
        }
    }
    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title>
                    Add bots from file
                </Modal.Title>
                <div className='app-modal-close' onClick={() => onClose()}>
                    <X size='2em' />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-table'>
                    <Table size='sm'>
                        <thead>
                        <tr>
                            <th className='bot-create-table-cell'>File</th>
                            <th className='bot-create-table-cell'>State</th>
                            <th className='bot-create-table-cell'>Bots</th>
                            <th className='bot-create-table-cell'></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.files.map((openFile, index) => <BotFile
                                key={`open-bot-file-${index}`}
                                file={openFile.file}
                                state={openFile.state} dispatchState={(e) => dispatchState({ type: 'FILE_EVENT', index: index, event: e })}
                                onRemove={() => dispatchState({ type: 'REMOVE_FILE', index: index })} />)
                        }
                        </tbody>
                    </Table>
                </div>
                <FormControl type='file' onChange={onFileInputChange} />
            </Modal.Body>
            <Modal.Footer>
                {state.totalState === 'empty' ? 'Add files with bots to continue' : null}
                {state.totalState === 'errored' ? 'Some of the files failed to load. Remove them to continue' : null}
                {state.totalState === 'loading' ? 'Some of the files are loading...' : null}
                {state.totalState === 'valid' ? <Button onClick={() => onContinue(state.totalBots)} disabled={state.totalBots.length === 0} variant='success'>Continue with {state.totalBots.length} bot(s)</Button> : null}
            </Modal.Footer>
        </Modal>
    )
}