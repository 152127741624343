import {Button, Card, Dropdown, Spinner} from "react-bootstrap";
import {CardImage, CardText, CheckCircle, ExclamationCircle, Globe2, PersonBadge, Upload} from "react-bootstrap-icons";
import {ReactNode, useState} from "react";
import {CreateUploadTask} from "./CreateUploadTask";
import {CreateTaskContextProvider, useCreateTask} from "./CreateTaskContext";
import {BotsInput, BotsInputProvider, useBotsInput} from "../../Bots/BotsInput";
import {useNavigate} from "react-router-dom";
import {AddTaskRequest, TaskType} from "../../Core/Model";
import {inflateExecutors} from "../../Utils/Utils";
import {CreateSetBioTask} from "./CreateSetBioTask";
import {CreateSetAvatarTask} from "./CreateSetAvatarTask";
import {CreateSetUsernameTask} from "./CreateSetUsernameTask";
import {CreateSetLoginNameTask} from "./CreateSetLoginNameTask";
import {useCache} from "../../Utils/UserCache";

interface TaskTypeEntry {
    key: string
    title: string
    icon: ReactNode
    type: TaskType
    // eslint-disable-next-line no-unused-vars
    element: (key: string) => ReactNode
}

const taskTable: TaskTypeEntry[] = [
    {
        key: 'upload',
        title: 'Create upload',
        icon: <Upload />,
        type: TaskType.REUPLOAD_VIDEO,
        element: (key) => <CreateUploadTask key={key} />
    },
    {
        key: 'set_avatar',
        title: 'Set avatar',
        icon: <CardImage />,
        type: TaskType.SET_AVATAR,
        element: (key) => <CreateSetAvatarTask key={key} />
    },
    {
        key: 'set_bio',
        title: 'Set bio',
        icon: <CardText />,
        type: TaskType.SET_BIO,
        element: (key) => <CreateSetBioTask key={key} />
    },
    {
        key: 'set_username',
        title: 'Set username',
        icon: <Globe2 />,
        type: TaskType.SET_USERNAME,
        element: (key) => <CreateSetUsernameTask key={key} />
    },
    {
        key: 'set_login_name',
        title: 'Set login name',
        icon: <PersonBadge />,
        type: TaskType.SET_LOGIN_NAME,
        element: (key) => <CreateSetLoginNameTask key={key} />
    }
]

const CreateTaskContent = (props: { type: TaskType, element: ReactNode }) => {
    const navigate = useNavigate()
    const createTask = useCreateTask()
    const botsInput = useBotsInput()
    const onCreateUpload = () => {
        if (!createTask.data.valid || !botsInput.botsValid.valid || createTask.data.data === null) {
            return
        }
        const [executorType, executors] = inflateExecutors(botsInput.botsValid.data)
        const request: AddTaskRequest = {
            executor_type: executorType,
            executor_ids: executors,
            task_type: props.type,
            task_data: createTask.data.data
        }
        createTask.createTask(request)
    }
    return(
        <>
            <Card.Body>
                {props.element}
                <BotsInputProvider value={botsInput}>
                    <BotsInput />
                </BotsInputProvider>
                {!createTask.create.loading && createTask.create.error !== null ? <div className='error-message' style={{ marginTop: '12px' }}>
                    <ExclamationCircle size='1.2em' color='red' />
                    <span>
                    Error occurred creating a task: {createTask.create.error.message}
                </span>
                </div> : null}
                {!createTask.create.loading && createTask.create.data !== null && createTask.create.data.task_id !== null ? <div className='success-message' style={{ marginTop: '12px' }}>
                    <CheckCircle size='1.2em' color='green' />
                    <span>
                    Task <Button onClick={() => navigate(`/task/${createTask.create.data?.task_id}`)} variant='outline-success' size='sm'>{createTask.create.data.task_id}</Button> successfully created!
                </span>
                </div> : !createTask.create.loading && createTask.create.data !== null ? <div className='error-message' style={{ marginTop: '12px' }}>
                    <ExclamationCircle size='1.2em' color='red' />
                    <span>
                        No tasks were scheduled because no <b>active</b> bots were found by your request
                    </span>
                </div> : null}
            </Card.Body>
            <Card.Footer>
                {createTask.create.loading ?
                    <div className='loading-wrapper'>
                        <Spinner />
                        {createTask.create.message !== null && <span className='loading-message'>{createTask.create.message}</span>}
                    </div>
                    : <Button disabled={!createTask.data.valid || !botsInput.botsValid.valid} onClick={onCreateUpload}>
                        Create task
                    </Button>}
            </Card.Footer>
        </>
    )
}

export const CreateTask = () => {
    const cache = useCache()
    const [selectedKey, setSelectedKey] = useState(cache.value('create_task_key', 'upload'))
    const onSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            cache.setValue('create_task_key', eventKey)
            setSelectedKey(eventKey)
        }
    }
    const menuItems = taskTable.map(entry => {
        return (
            <Dropdown.Item key={entry.key} eventKey={entry.key}>
                {entry.icon} {entry.title}
            </Dropdown.Item>
        )
    })
    const selectedEntry = taskTable.find(e => e.key === selectedKey) ?? taskTable[0]
    return (
        <div>
            <CreateTaskContextProvider>
                <Card>
                    <Card.Header>
                        <Dropdown onSelect={onSelect}>
                            <Dropdown.Toggle variant='outline-primary' as={Button}>
                                {selectedEntry.icon} {selectedEntry.title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {menuItems}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Header>
                    <CreateTaskContent type={selectedEntry.type} element={selectedEntry.element(selectedEntry.key)} />
                </Card>
            </CreateTaskContextProvider>
        </div>
    )
}