import {Button, Form, FormGroup, Modal, Spinner} from "react-bootstrap";
import {X} from "react-bootstrap-icons";
import {ChangeEvent, useReducer} from "react";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {AddBotGroupRequest, AddBotGroupResponse} from "../Core/Model";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useApi} from "../Core/ApiContextProvider";

interface CreateGroupData {
    name: string
    description: string
}

interface CreateGroupState {
    data: CreateGroupData,
    errors: string[],
    add: CallState<AddBotGroupResponse>
}

type CreateGroupEvent = {
    type: 'SET_NAME',
    value: string
} | {
    type: 'SET_DESCRIPTION',
    value: string
} | {
    type: 'CALL_UPDATE',
    call: 'add',
    update: CallUpdate
} | {
    type: 'RESET'
}

function validate(data: CreateGroupData): string[] {
    if (data.name.length < 3) {
        return ['Group name must be at least 3 characters long']
    }
    return []
}

function newState(): CreateGroupState {
    const data = { name: '', description: '' }
    return {
        data: data,
        errors: validate(data),
        add: newCall()
    }
}

// eslint-disable-next-line no-unused-vars
export const CreateGroupModal = (props: { show: boolean, onClose: (changed: boolean) => void }) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: CreateGroupState, event: CreateGroupEvent) => {
        switch (event.type) {
            case "SET_NAME": {
                const data = {...state.data, name: event.value}
                return {...state, data: data, errors: validate(data)}
            }
            case "SET_DESCRIPTION": {
                const data = {...state.data, description: event.value}
                return {...state, data: data, errors: validate(data)}
            }
            case 'CALL_UPDATE': {
                switch (event.call) {
                    case 'add': {
                        return { ...state, add: callDispatch(state.add, event.update) }
                    }
                }
                break;
            }
            case 'RESET':
                return newState()
        }
        return state
    }, newState())
    const onClose = () => {
        const changed = state.add.data !== null
        dispatchState({ type: 'RESET' })
        props.onClose(changed)
    }
    const dispatchChange = (eventName: 'SET_NAME' | 'SET_DESCRIPTION') => (event: ChangeEvent<HTMLInputElement>) => {
        dispatchState({ type: eventName, value: event.target.value ?? '' })
    }
    const onCreateSubmit = () => {
        dispatchState({ type: 'CALL_UPDATE', call: 'add', update: { type: 'LOADING', message: 'Creating group...' } })
        const request: AddBotGroupRequest = {...state.data}
        api.groupsAdd(request)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'add', update: { type: 'DATA', data: e } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'add', update: { type: 'ERROR', error: e } }))
    }
    const getModalContent = () => {
        if (state.add.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner />
                    {state.add.message !== null && <span className='loading-message'>{state.add.message}</span>}
                </div>
            )
        }
        if (state.add.data) {
            return (
                <div className='success-message'>
                    Group <Button size='sm' variant='outline-success'>{state.add.data.created_group_id}</Button> is successfully created
                </div>
            )
        }
        return (
             <>
                 <FormGroup className="mb-3">
                    <Form.Label>Group name</Form.Label>
                    <Form.Control type='text' placeholder='Instagram Group 1' value={state.data.name} onChange={dispatchChange('SET_NAME')}/>
                 </FormGroup>
                 <FormGroup className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder='' value={state.data.description} onChange={dispatchChange('SET_DESCRIPTION')}/>
                    <Form.Text>(Optional)</Form.Text>
                 </FormGroup>
                 <FormGroup>
                     {state.add.error && <div className='error-message'>
                         Failed to create group: {state.add.error.message}
                     </div> }
                 </FormGroup>
            </>
        )
    }
    return (
        <Modal show={props.show}>
            <Modal.Header className='app-modal-header'>
                <Modal.Title>
                    <div className='app-modal-title'>
                        Add bots
                    </div>
                </Modal.Title>
                <div className='app-modal-close' onClick={onClose}>
                    <X size='2em' />
                </div>
            </Modal.Header>
            <Modal.Body>
                {getModalContent()}
            </Modal.Body>
            <Modal.Footer>
                {state.add.data === null ? <SimpleTooltip
                    tooltip={state.errors.length > 0 ?
                        state.errors.map((e, idx) => <div key={`error-${idx}`}>{e}</div>) :
                        state.add.loading ? 'Creating...' : 'Data is valid'}>
                    <Button onClick={onCreateSubmit} disabled={state.add.loading || state.errors.length > 0}>Create group</Button>
                </SimpleTooltip> : <Button onClick={onClose}>Close</Button>}
            </Modal.Footer>
        </Modal>
    )
}