import {
    AddBotGroupResponse,
    BotGroup, BotInfo,
    BotListInfo, RemoveBotsFromGroupRequest,
    UpdateBotGroupRequest
} from "../Core/Model";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useEffect, useReducer} from "react";
import {useApi} from "../Core/ApiContextProvider";
import {Badge, Button, Col, Container, Dropdown, Row, Spinner, Table} from "react-bootstrap";
import {BlockTitle} from "../Utils/BlockTitle";
import {ArrowRepeat, DashLg, Files, PlusLg, Trash3, XLg} from "react-bootstrap-icons";
import {ClosableProps} from "../Utils/Closable";
import {PaginationDirectionControls} from "../Utils/Pagination";
import {useCache} from "../Utils/UserCache";
import {SimpleTooltip} from "../Utils/SimpleTooltip";
import {DotsToggle} from "../Utils/DotsToggle";
import {copyTextToClipboard} from "../Utils/ClipboardUtils";
import {GroupBotsAdd} from "./GroupBotsAdd";
import {Editable} from "../Utils/Editable";

export interface GroupDetailsProps extends ClosableProps {
    groupId: number
}

enum Mode {
    LIST = 'list',
    ADD = 'add',
    DELETE = 'delete'
}

interface GroupDetailState {
    get: CallState<BotGroup>,
    bots: CallState<BotListInfo>,
    add: CallState<AddBotGroupResponse>,
    mode: Mode,
    deleteBots: BotInfo[]
}

type GroupDetailEvent = {
    type: 'CALL_UPDATE',
    call: 'get' | 'bots' | 'add',
    update: CallUpdate
} | {
    type: 'TOGGLE_BOT_ADD',
    update: boolean
} | {
    type: 'TOGGLE_BOT_DELETE',
    update: boolean
} | {
    type: 'TOGGLE_DELETE_BOT_SELECTION',
    bot: BotInfo
}

function newState(): GroupDetailState {
    return {
        get: newCall(),
        bots: newCall(),
        add: newCall(),
        mode: Mode.LIST,
        deleteBots: []
    }
}


export const GroupDetails = (props: GroupDetailsProps) => {
    const api = useApi()
    const cache = useCache()
    const [state, dispatchState] = useReducer((state: GroupDetailState, event: GroupDetailEvent) => {
        switch (event.type) {
            case "CALL_UPDATE": {
                switch (event.call) {
                    case "bots": {
                        return { ...state, bots: callDispatch(state.bots, event.update) }
                    }
                    case "get": {
                        return { ...state, get: callDispatch(state.get, event.update) }
                    }
                    case 'add': {
                        return { ...state, add: callDispatch(state.add, event.update) }
                    }
                }
                break;
            }
            case "TOGGLE_BOT_ADD": {
                const refreshIndicator = event.update ? !state.bots.refreshIndicator : state.bots.refreshIndicator;
                const mode = state.mode === Mode.ADD ? Mode.LIST : Mode.ADD;
                return { ...state, mode: mode, bots: { ...state.bots, refreshIndicator: refreshIndicator }, deleteBots: [] }
            }
            case 'TOGGLE_BOT_DELETE': {
                const mode = state.mode === Mode.DELETE ? Mode.LIST : Mode.DELETE;
                const deleteBots = mode !== Mode.DELETE ? [] : state.deleteBots;
                return { ...state, mode: mode, deleteBots: deleteBots }
            }
            case 'TOGGLE_DELETE_BOT_SELECTION': {
                const isSelected = state.deleteBots.find(e => e.bot_id === event.bot.bot_id)
                const deleteBots = isSelected ? state.deleteBots.filter(e => e.bot_id !== event.bot.bot_id)
                    : [ ...state.deleteBots, event.bot ]
                return { ...state, deleteBots: deleteBots}
            }
        }
        return state
    }, newState())
    const getBotsDescription = () => {
        if (state.bots.loading) {
            return state.bots.message
        }
        if (state.bots.error) {
            return `Failed to load: ${state.bots.error.message}`
        }
        if (state.bots.data) {
            if (state.mode === Mode.LIST || state.mode === Mode.DELETE) {
                return `${state.bots.data.total_count} bots found`
            }
        }
        return ''
    }
    const onEdit = (field: 'description' | 'name') => (value: string | null): Promise<string | null> => {
        const request: UpdateBotGroupRequest = {}
        request[field] = value ?? '' // set corresponding field in request, replace null with empty string
        return api.groupUpdate(props.groupId, request)
            .then(response => api.groupGet(response.updated_group_id)) // request updated data for group after update
            .then(e => e[field]) // get updated field from response
        // BUG: update_date & other updates are not being updated on UI after edit
        // Make sense to make refresh?
    }
    const onDelete = () => {
        const deleteBots = state.deleteBots
        if (deleteBots.length === 0) {
            dispatchState({ type: 'TOGGLE_BOT_DELETE', update: false })
            return
        }
        const ids = deleteBots.map(e => e.bot_id)
        const request: RemoveBotsFromGroupRequest = { bot_ids: ids }
        dispatchState({ type: 'CALL_UPDATE', call: 'bots', update: { type: 'LOADING', message: 'Removing selected bots...' } })
        api.groupBotsRemove(props.groupId, request)
            .then(() => api.groupBotsList(props.groupId, state.bots.pagination.offset, state.bots.pagination.limit))
            .then(e => {
                dispatchState({type: 'CALL_UPDATE', call: 'bots', update: { type: 'DATA', data: e }})
                dispatchState({ type: 'TOGGLE_BOT_DELETE', update: true })
            })
            .catch(e => dispatchState({type: 'CALL_UPDATE', call: 'bots', update: { type: 'ERROR', error: e }}))
    }
    useEffect(() => {
        dispatchState({type: 'CALL_UPDATE', call: 'get', update: { type: 'LOADING', message: 'Loading group info...' }})
        api.groupGet(props.groupId)
            .then(e => dispatchState({type: 'CALL_UPDATE', call: 'get', update: { type: 'DATA', data: e }}))
            .catch(e => dispatchState({type: 'CALL_UPDATE', call: 'get', update: { type: 'ERROR', error: e }}))
    }, [state.get.refreshIndicator])
    useEffect(() => {
        dispatchState({type: 'CALL_UPDATE', call: 'bots', update: { type: 'LOADING', message: 'Loading bots...' }})
        api.groupBotsList(props.groupId, state.bots.pagination.offset, state.bots.pagination.limit)
            .then(e => dispatchState({type: 'CALL_UPDATE', call: 'bots', update: { type: 'DATA', data: e }}))
            .catch(e => dispatchState({type: 'CALL_UPDATE', call: 'bots', update: { type: 'ERROR', error: e }}))
    }, [state.bots.refreshIndicator, state.bots.pagination.limit, state.bots.pagination.offset])
    return (
        <>
            <Container className='app-tab-content'>
                <BlockTitle
                    closeType={props.closeType}
                    onClose={props.onClose}
                    title={state.get.data ? <>
                        Bot Groups {'>'} {state.get.data.name} <SimpleTooltip tooltip={<div>Status ID: {state.get.data.status}</div>}>
                        <Badge bg={state.get.data.variant}>{state.get.data.status_name}</Badge>
                    </SimpleTooltip>
                    </> : `Bot Groups > #${props.groupId}`}
                    description={
                        state.get.loading ? state.get.message :
                            state.get.error ? `Failed to load: ${state.get.error.message}` :
                                state.get.data ? '' : ''
                    } />
                <div className='table-toolbar'>
                    <Button disabled={state.bots.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'bots', update: { type: 'REFRESH' } })}>
                        <div className='icon-button'>
                            <ArrowRepeat size='1.2em' title='Refresh' />
                        </div>
                    </Button>
                </div>
                <Row>
                    <Col>
                        <BlockTitle title='Summary' description={''} />
                        <Table>
                            {state.get.data !== null && !state.get.loading ? <tbody>
                            <tr>
                                <td className='col-1'>Name</td>
                                <td className='col-3'><Editable onSave={onEdit('name')} allowBlank={false} multiline={false} value={state.get.data.name} /></td>
                            </tr>
                            <tr>
                                <td className='col-1'>Created</td>
                                <td className='col-3'>{state.get.data.create_date.local}</td>
                            </tr>
                            <tr>
                                <td className='col-1'>Updated</td>
                                <td className='col-3'>{state.get.data.update_date.local}</td>
                            </tr>
                            <tr>
                                <td className='col-1'>Description</td>
                                <td className='col-3'><Editable onSave={onEdit('description')} allowBlank={true} multiline={true} value={state.get.data.description} /></td>
                            </tr>
                            </tbody> : null}
                        </Table>
                    </Col>
                </Row>
                <div>
                    <BlockTitle
                        title={state.mode === Mode.ADD ? `Add bots` : `Bots`}
                        description={getBotsDescription()}
                        closeType={state.mode === Mode.ADD ? 'close' : undefined}
                        onClose={state.mode === Mode.ADD ? () => dispatchState({ type: 'TOGGLE_BOT_ADD', update: true }) : undefined} />
                    {state.mode === Mode.ADD ? <GroupBotsAdd groupId={props.groupId} onFinish={() => dispatchState({ type: 'TOGGLE_BOT_ADD', update: true })} /> : <div className='table-wrapper'>
                        <div className='table-toolbar'>
                            <Button disabled={state.bots.loading} onClick={() => dispatchState({ type: 'TOGGLE_BOT_ADD', update: false })}>
                                <div className='icon-button'>
                                    <PlusLg size='1.2em' title='Add bots' />
                                    <span>Add bots</span>
                                </div>
                            </Button>
                            <Button disabled={state.bots.loading} onClick={() => dispatchState({ type: 'CALL_UPDATE', call: 'bots', update: { type: 'REFRESH' } })}>
                                <div className='icon-button'>
                                    <ArrowRepeat size='1.2em' title='Refresh' />
                                    <span>Refresh</span>
                                </div>
                            </Button>
                            {state.mode === Mode.DELETE ?
                                <>
                                    <Button variant={'danger'} disabled={state.bots.loading || state.deleteBots.length === 0} onClick={onDelete}>
                                        <div className='icon-button'>
                                            <Trash3 />
                                            <span>Remove {state.deleteBots.length} bots</span>
                                        </div>
                                    </Button>
                                    <Button variant='outline-danger' disabled={state.bots.loading} onClick={() => dispatchState({ type: 'TOGGLE_BOT_DELETE', update: false })}>
                                        <div className='icon-button'>
                                            <XLg />
                                            <span>Cancel delete</span>
                                        </div>
                                    </Button>
                                </>
                                :
                                <Button variant='danger' disabled={(state.bots.data && state.bots.data.total_count === 0) || state.bots.loading} onClick={() => dispatchState({ type: 'TOGGLE_BOT_DELETE', update: false })}>
                                    <div className='icon-button'>
                                        <Trash3 size='1.2em' title='Refresh' />
                                        <span>Remove bots</span>
                                    </div>
                                </Button>
                            }
                        </div>
                        <div className='overflow-table-wrapper'>
                            <Table striped hover className='full-width'>
                                <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        Created
                                    </th>
                                    <th>
                                        Login
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Last Update
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.bots.data !== null && state.bots.data.bots.length > 0 ?
                                    state.bots.data.bots.map(bot => {
                                        const isSelected = state.deleteBots.find(e => e.bot_id === bot.bot_id) !== undefined
                                        return (
                                            <tr key={bot.bot_id}>
                                                <td>
                                                    {bot.bot_id}
                                                </td>
                                                <td>
                                                    <SimpleTooltip tooltip={(
                                                        <div>UTC: {bot.create_date.utc}</div>
                                                    )}>
                                                        {bot.create_date.local}
                                                    </SimpleTooltip>
                                                </td>
                                                <td>
                                                    {bot.login}
                                                </td>
                                                <td>
                                                    <SimpleTooltip tooltip={<div>Status ID: {bot.status}</div>}>
                                                        <Badge bg={bot.status_bg}>{bot.status_name}</Badge>
                                                    </SimpleTooltip>
                                                </td>
                                                <td>
                                                    <SimpleTooltip tooltip={(
                                                        <div>UTC: {bot.update_date.utc}</div>
                                                    )}>
                                                        {bot.update_date.local}
                                                    </SimpleTooltip>
                                                </td>
                                                <td>
                                                    {state.mode === Mode.DELETE ?
                                                        <Button size='sm' variant={isSelected ? 'danger' : 'outline-danger'} onClick={() => dispatchState({ type: 'TOGGLE_DELETE_BOT_SELECTION', bot: bot })}>
                                                            <Trash3 />
                                                        </Button>
                                                        :
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={DotsToggle}></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.ItemText>
                                                                    Bot {bot.bot_id}
                                                                </Dropdown.ItemText>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Header>Session Key</Dropdown.Header>
                                                                <Dropdown.Item disabled={!bot.session_key} onClick={() => bot.session_key ? copyTextToClipboard(bot.session_key) : null}>
                                                                    <div className='icon-button'>
                                                                        <Files /> <span>{bot.session_key ?? "N/A"}</span>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item>
                                                                    <div className='icon-button text-danger'>
                                                                        <DashLg /> <span>Delete from group</span>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>}
                                                </td>
                                            </tr>
                                        )
                                    }) : null}
                                {state.bots.data !== null && state.bots.data.bots.length === 0 ?
                                    <tr>
                                        <td colSpan={5} className='center-text'>
                                            <div>Group does not have any bots</div>
                                            <div>
                                                <Button size='sm' variant='outline-primary' onClick={() => dispatchState({ type: 'TOGGLE_BOT_ADD', update: false })}>
                                                    <div className='icon-button'>
                                                        <PlusLg size='1.2em' title='Add bots' />
                                                        <span>Add bots</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr> : null}
                                {state.bots.error !== null && !state.bots.loading ?
                                    <tr>
                                        <td colSpan={6} className='center-text'>
                                            <div>Error occurred loading bots</div>
                                            <div>{state.bots.error.message}</div>
                                        </td>
                                    </tr>
                                    : null}
                                </tbody>
                            </Table>
                        </div>
                        <PaginationDirectionControls
                            call={state.bots}
                            show={!state.bots.loading}
                            totalCountFn={(data) => data.total_count}
                            onPaginationControl={(d) => dispatchState({ type: 'CALL_UPDATE', call: 'bots', update: { type: 'PAGINATION', direction: d } })}
                            onPaginationLimit={(limit) => dispatchState({ type: 'CALL_UPDATE', call: 'bots', update: { type: 'LIMIT', limit: cache.setNumber('group_bots_limit', limit) } })} />
                        {state.bots.loading ? <div className='loading-wrapper'>
                            <Spinner />
                            {state.bots.message !== null && <span className='loading-message'>
                            {state.bots.message}
                        </span>}
                        </div> : null}
                    </div>}
                </div>
            </Container>
        </>
    )
}